<template>
    <div class="modal fade" id="ContainerDocCreate" tabindex="-1" aria-labelledby="ContainerDocCreateLabels"
         aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header p-3">
                    <h5 class="modal-title" id="ContainerDocCreateLabels">Add Container Document</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form class="form">
                        <div v-if="doc === null" class="mb-3">
                            <label class="form-label">Container Document</label>
                            <input type="file" id="container_document" class="form-control" placeholder="Document"
                                   @change="imgChanges">
                        </div>
                        <div v-if="doc !== null">
                            <div class="border rounded-2 p-3 pt-3 mb-3">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <h6 class="mb-1"> {{ doc.name }}</h6>
                                        <span>{{ doc.size }}</span>
                                    </div>
                                    <div>
                                        <b-button @click="deleteDoc()" variant="danger" class="btn-sm">
                                            <i class="bx bx-trash me-1"></i>
                                            Delete
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                            <div class="text-end">
                                <b-button @click="addDocument()" variant="success">Add Document</b-button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    emits: ['update'],
    name: "container_doc_create",
    props: {
        doc_id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            doc: null,
        }
    },
    methods: {
        imgChanges(e) {
            this.doc = e.target.files[0]
        },
        deleteDoc() {
            this.doc = null
        },
        addDocument() {
            let data = new FormData();
            data.append('documents[0]', this.doc);

            let config = {
                method: 'post',
                url: `/terminal/containers/${this.doc_id}/document/create/`,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: data
            };

            axios(config)
                .then(function (response) {
                    if (response.status === 201) {
                        document.querySelector('#ContainerDocCreate .btn-close').click();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            this.$emit('update')
        }
    },
}
</script>

<style scoped>

</style>