<script>
import {defineComponent} from 'vue'
import selectTerminal from "@/components/custom/SelectTerminal.vue";
import SelectContainer from "@/components/custom/SelectContainer.vue";
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";
import axios from "axios";
import Swal from "sweetalert2";

export default defineComponent({
    emits: ["update"],
    name: "container_create",
    components: {selectTerminal, SelectContainer, Multiselect},
    data() {
        return {
            container: {
                id: '',
                terminal_id: '',
                status: '',
                arrival_date: '',
            },
            status_options: [
                {
                    value: 'pending',
                    label: 'Pending'
                },
                {
                    value: 'available',
                    label: 'Available'
                },
                {
                    value: 'dispatched',
                    label: 'Dispatched'
                },
            ]
        }
    },
    methods: {
        async createContainer() {
            let response = await axios.post('/terminal/containers/create/', {
                container_id: this.container.id,
                terminal_id: this.container.terminal_id,
                container_status: this.container.status,
                arrival_date: this.container.arrival_date,
            })
            const Toast = Swal.mixin({
                toast: true,
                position: 'top',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            if (response.status === 201) {
                document.querySelector('#ContainerCreate .btn-close').click()
                this.container = {
                    id: '',
                    terminal_id: '',
                    status: '',
                    date: '',
                }
                this.$emit('update')
                await Toast.fire({
                    icon: 'success',
                    title: 'Container created successfully',
                })
            } else {
                await Toast.fire({
                    icon: 'error',
                    title: 'Something went wrong',
                })
            }
        }
    }
})
</script>

<template>
    <div class="modal fade" id="ContainerCreate" tabindex="-1" aria-labelledby="ContainerCreateLabels"
         aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header p-3">
                    <h5 class="modal-title" id="ContainerCreateLabels">Create Container</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form class="form" @submit.prevent="createContainer">
                        <div class="mb-3">
                            <label class="form-label">Container Name</label>
                            <SelectContainer @onSelect="container.id = $event.value"/>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Terminal</label>
                            <selectTerminal @onSelect="container.terminal_id = $event.value"/>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Status</label>
                            <Multiselect :options="status_options"
                                         v-model="container.status"
                                         placeholder="Select Status"/>
                        </div>
                        <div class="mb-4">
                            <label class="form-label">Date</label>
                            <input v-model="container.arrival_date" type="date" class="form-control"
                                   placeholder="Date" >
                        </div>
                        <div class="text-end">
                            <b-button variant="success" type="submit">Create</b-button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>