<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "TerminalUpdate",
  data() {
    return {
      showModal: false,
      terminal: null,
      is_saving: false
    }
  },
  computed: {
    terminal_computed() {
      return this.terminal || {
        name: null,
        free_days: 0
      }
    }
  },
  methods: {
    openModal(terminal) {
      if (!terminal || !terminal.id) return alert('Terminal not found');
      this.terminal = terminal;
      this.showModal = true;
    },
    async updateTerminal() {
      this.is_saving = true;
      try {
        await axios.put(`/terminal/${this.terminal.id}/update/`, {
          name: this.terminal.name,
          free_days: this.terminal.free_days,
          active: true
        })
        this.is_saving = false;
        this.showModal = false;
        let Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 3000,
        })
        await Toast.fire({
          icon: "success",
          title: "Terminal updated",
        })
        await this.$emit('terminalUpdated');
      } catch (e) {
        this.is_saving = false;
        try {
          await Swal.fire({
            icon: "error",
            title: "Error updating terminal",
            html: Object.entries(e.response.data.extra.fields).map(([key, value]) => {
              return `<b>${key.split('_')
                  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' ')}:</b> ${value.join(', ')} <br>`
            }).join('\n') || "Please, inform developers about this issue",
          })
        } catch {
          await Swal.fire({
            icon: "error",
            title: "Error updating terminal",
            text: "Please, inform developers about this issue",
          })
        }
      }
    }
  },
  watch: {
    terminal: {
      handler(newVal) {
        if (newVal && newVal.free_days > 100) {
          this.terminal.free_days = 100;
        }
      },
      deep: true
    }
  }
}
</script>

<template>
  <b-modal v-model="showModal" hide-footer
           title="Terminal Update" size="sm"
           class="v-modal-custom" centered
  >
    <form @submit.prevent="updateTerminal()" v-if="terminal" class="d-flex flex-column gap-3">
      <div>
        <label class="form-label">Terminal name</label>
        <input class="form-control" v-model="terminal.name" placeholder="Enter terminal name">
      </div>
      <div>
        <label class="form-label">Free days</label>
        <input class="form-control" type="number" v-model="terminal.free_days"
               placeholder="Enter terminal name">
      </div>
      <div>
        <b-button v-if="!is_saving" type="submit" variant="success" class="w-100">
          <i class="mdi mdi-check-bold me-1"></i>
          Save
        </b-button>
        <b-button v-else type="button" variant="success" class="w-100" disabled>
          <i class="mdi mdi-circle-multiple-outline mdi-spin me-1"></i>
          Saving...
        </b-button>
      </div>
    </form>
  </b-modal>
</template>

<style scoped>

</style>