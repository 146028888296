<script>
import axios from "axios";
import SelectTerminal from "@/components/custom/SelectTerminal.vue";

export default {
  name: "ConnectOwners",
  emits: ['onSuccess'],
  components: {
    SelectTerminal
  },
  data() {
    return {
      showModal: false,
      containers_plain_text: '',
      container_types: '',
      arrival_dates: '',
      is_laden: true,
      terminal: null,
      process_stage: 'input', // input, loading, success, error,
      error_data: {},
      response: {
        type: 'initial',
        message: '',
        details: ''
      },

      has_added_new_containers: false,
    }
  },
  methods: {
    openModal() {
      if (this.has_added_new_containers) {
        this.resetModal()
      }
      this.showModal = true
    },
    closeModal() {
      this.showModal = false
    },

    async addContainers() {
      if (!this.canSubmitForm) return;
      this.response.type = 'loading'
      try {
        let response = await axios.post('/terminal/container_add_list/', {
          containers: this.containers_plain_text.split('\n').map(container => container.trim()),
          container_types: this.container_types.split('\n').map(wagon => wagon.trim()),
          arrival_dates: this.arrival_dates.split('\n').map(date => date.trim()),
          terminal_id: this.terminal.id,
          is_laden: this.is_laden
        })
        this.has_added_new_containers = true
        this.response = {
          type: 'success',
          message: 'Successfully added containers',
          details: response.data.data
        }
        this.$emit('onSuccess')
      } catch (err) {
        this.response.type = 'error'
        try {
          this.response.message = err.response.data.message || 'Something went wrong'
          this.response.details = err.response.data.errors.join('<br>')
        } catch {
          this.response.message = 'Something went wrong'
          this.response.details = 'Please try again'
        }
        this.response.type = 'error'
      }
    },
    resetModal() {
      this.showModal = false
      this.process_stage = 'input'
      this.containers_data = []
      this.containers_plain_text = ''
      this.container_types = ''
      this.arrival_dates = ''
      this.response = {
        type: 'initial',
        message: '',
        details: ''
      }
    }
  },
  computed: {
    incorrect_containers() {
      return this.containers_plain_text.split('\n')
          .map(container => container.trim())
          .filter(container => container.length !== 11 || !/^[A-Z]{3}U[0-9]{7}$/.test(container))
    },
    incorrect_container_types() {
      const lines = this.container_types.split('\n');
      const validTypes = ['20', '20HC', '40', '40HC', '45'];

      return lines.filter(line => {
        const trimmedLine = line.trim();
        return trimmedLine && !validTypes.includes(trimmedLine);
      });
    },
    incorrect_arrival_dates() {
      if (this.arrival_dates.trim().length === 0) {
        return [];
      }
      const currentYear = new Date().getFullYear();
      const lines = this.arrival_dates.split('\n');

      return lines.filter(line => {
        if (!/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(line.trim())) {
          return true;
        }

        const [year, month, day] = line.split('-').map(Number);

        if (year > currentYear) {
          return true;
        }

        if (month < 1 || month > 12) {
          return true;
        }

        const daysInMonth = new Date(year, month, 0).getDate();
        return day < 1 || day > daysInMonth;
      });
    },

    containers_count() {
      return this.containers_plain_text.trim().length ? this.containers_plain_text.split('\n').length : 0
    },
    container_types_count() {
      return this.container_types.trim().length ? this.container_types.split('\n').length : 0
    },
    arrival_dates_count() {
      return this.arrival_dates.trim().length ? this.arrival_dates.split('\n').length : 0
    },

    canSubmitForm() {
      let containers_length = this.containers_plain_text.split('\n').length
      let container_types_length = this.container_types.split('\n').length
      let arrival_dates_length = this.arrival_dates.split('\n').length

      return this.terminal
          && this.incorrect_containers.length === 0
          && this.incorrect_container_types.length === 0
          && this.containers_plain_text.length >= 11
          && this.container_types.length >= 2
          && this.arrival_dates.length >= 9
          && this.incorrect_arrival_dates.length === 0
          && containers_length === container_types_length
          && container_types_length === arrival_dates_length
    },

  },
}
</script>

<template>
  <b-modal
      v-model="showModal" id="addContainersModal" modal-class="zoomIn" hide-footer
      header-class="p-3 bg-soft-success" class="v-modal-custom" centered size="lg"
      title="Add Containers"
  >

    <form @submit.prevent="addContainers()" :class="{'d-none' : response.type !=='initial'}">

      <div class="mb-3">
        <label for="selectTerminal" class="form-label">Select Terminal</label>
        <SelectTerminal @onSelect="terminal = $event ? { id: $event.value, name: $event.label } : null"/>
      </div>

      <b-row gutter-y="4">
        <b-col sm="12" lg="4">
          <label class="form-label" for="dispatchContainersNames">Container Names</label>
          <textarea
              class="form-control mb-2"
              id="dispatchContainersNames" rows="20"
              v-model="containers_plain_text"
              placeholder="Paste containers names here"
          >
          </textarea>
          <VTooltip>
            <span id="dispatchContainersNamesHelp" class="form-text">
              <i class="ri-information-line align-middle"></i>
              Must be separated by line
              <span class="fw-bold">({{ containers_count }})</span>
            </span>
            <template v-slot:popper>
              Each container name must be 11 characters long <br>
              Each line represents a single container name
            </template>
          </VTooltip>
        </b-col>
        <b-col sm="12" lg="4">
          <label class="form-label" for="dispatchWagonsNames">Container Types</label>
          <textarea
              class="form-control mb-2"
              id="dispatchWagonsNames" rows="20"
              v-model="container_types"
              placeholder="Paste container types here"
          >
          </textarea>
          <VTooltip>
            <span id="dispatchWagonsNamesHelp" class="form-text">
              <i class="ri-information-line align-middle"></i>
              Must be separated by line
              <span class="fw-bold">({{ container_types_count }})</span>
            </span>
            <template v-slot:popper>
              Each container type must contain only valid options <br>
              20, 20HC, 40, 40HC, 45
            </template>
          </VTooltip>
        </b-col>
        <b-col sm="12" lg="4">
          <label class="form-label" for="dispatchDates">Arrival Dates</label>
          <textarea
              class="form-control mb-2"
              id="dispatchDates" rows="20"
              v-model="arrival_dates"
              placeholder="Paste arrival dates here"
          >
          </textarea>
          <VTooltip>
            <span id="dispatchDatesHelp" class="form-text">
              <i class="ri-information-line align-middle"></i>
              Must be in YYYY-MM-DD format
              <span class="fw-bold">({{ arrival_dates_count }})</span>
            </span>
            <template v-slot:popper>
              Each date must be in YYYY-MM-DD format <br>
            </template>
          </VTooltip>
        </b-col>
        <b-col sm="12" class="text-center mt-4">
          <div class="form-check mb-0">
            <input v-model="is_laden" class="form-check-input" type="checkbox" id="isLadenCheckbox">
            <label class="form-check-label" for="isLadenCheckbox">
              Is Laden
            </label>
          </div>
        </b-col>
        <b-col sm="12" class="text-center mt-4">

          <div class="text-start mb-3" v-if="incorrect_containers.filter(a => a).length >0">
            <b-alert show variant="danger" class="d-flex gap-3 flex-wrap text-danger border-0 "
                     role="alert">
              <div class="d-flex flex-column">
                <h6 class="text-danger d-block">Incorrect Containers</h6>
                <span v-for="ctr in incorrect_containers"
                      :key="`incorrect_container_${ctr}`"
                >
                  <span v-if="ctr">{{ ctr || '--' }}</span>
                  <span v-else>-</span>
                </span>
              </div>
            </b-alert>
          </div>
          <div class="text-start mb-3" v-if="incorrect_container_types.length > 0">
            <b-alert show variant="danger" class="d-flex gap-3 flex-wrap text-danger border-0 "
                     role="alert">
              <div class="d-flex flex-column">
                <h6 class="text-danger d-block">Incorrect Wagons</h6>
                <span v-for="type in incorrect_container_types"
                      :key="`incorrect_type_${type}`"
                >
                  <span v-if="type">{{ type || '--' }}</span>
                  <span v-else>-</span>
                </span>
              </div>
            </b-alert>
          </div>
          <div class="text-start" v-if="incorrect_arrival_dates.length > 0">
            <b-alert show variant="danger" class="d-flex gap-3 flex-wrap text-danger border-0 "
                     role="alert"
            >
              <div class="d-flex flex-column">
                <h6 class="text-danger d-block">Incorrect Dates</h6>
                <span v-for="date in incorrect_arrival_dates"
                      :key="`incorrect_date_${date}`"
                >
              <span v-if="date">{{ date || '--' }}</span>
              <span v-else>-</span>
              </span>
              </div>
            </b-alert>
          </div>

          <b-button type="submit" variant="success"
                    :disabled="!canSubmitForm"
          >
            Confirm & Add Containers
          </b-button>
        </b-col>
      </b-row>
    </form>

    <div v-if="response.type === 'loading'">
      <div class="text-center py-5">
        <div class="spinner-border text-success mb-4" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <h3>Adding to containers...</h3>
      </div>
    </div>
    <div v-else-if="response.type ==='success'">
      <div class="text-center py-2">
        <i class="mdi mdi-check-circle-outline text-success mb-4" style="font-size: 5rem"></i>
        <h2 class="my-3 mb-3">
          {{ response.message }}
        </h2>
      </div>
      <b-alert v-if="response.details.filter(c => c.status === 'old').length > 0" dismissible show variant="info"
               class="alert-additional fade show" role="alert">
        <div class="alert-body">
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          <div class="d-flex">
            <div class="flex-shrink-0 me-3">
              <i class="ri-information-fill fs-16 align-middle"></i>
            </div>
            <div class="flex-grow-1">
              <h5 class="alert-heading mb-3">
                Existing Containers Found
                <span class="badge badge-gradient-secondary align-middle ms-1">
                    {{ response.details.filter(c => c.status === 'old').length }}
                  </span>
              </h5>
              <div class="d-flex flex-wrap gap-2 text-truncate w-100 fs-5 mb-0">
                <span v-for="ctr in response.details.filter(c => c.status === 'old')" :key="`new_ctr_${ctr}`">
                  {{ ctr.container }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </b-alert>
      <b-alert v-if="response.details.filter(c => c.status === 'new').length > 0" dismissible show variant="success"
               class="alert-additional fade show text-start" role="alert">
        <div class="alert-body">
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          <div class="d-flex">
            <div class="flex-shrink-0 me-3">
              <i class="ri-user-smile-line fs-16 align-middle"></i>
            </div>
            <div class="flex-grow-1">
              <h5 class="alert-heading align-middle mb-3">
                New Containers Added
                <span class="badge badge-gradient-info align-middle ms-1">
                    {{ response.details.filter(c => c.status === 'new').length }}
                  </span>
              </h5>
              <div class="d-flex flex-wrap gap-2 text-truncate w-100 fs-5 mb-0">
                <span v-for="ctr in response.details.filter(c => c.status === 'new')" :key="`new_ctr_${ctr}`">
                  {{ ctr.container }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </b-alert>
      <div class="text-center">
        <b-button variant="success" @click="closeModal()">OK</b-button>
      </div>
    </div>
    <div v-else-if="response.type ==='error'">
      <div class="text-center">
        <i class="mdi mdi-close-circle-outline text-danger" style="font-size: 5rem"></i>
        <h2 class="my-3 mb-3">
          {{ response.message }}
        </h2>
        <p class="fs-4 mb-4" v-html="response.details"/>
        <b-button size="lg" variant="success" @click="process_stage = 'input'">Try Again</b-button>
      </div>
    </div>
  </b-modal>

</template>

<style scoped>

</style>