<script>
import {defineComponent} from 'vue'
import TelegramHeader from "@/components/telegram/Header.vue";
import TelegramBody from "@/components/telegram/Body.vue";
import TelegramFooter from "@/components/telegram/Footer.vue";

export default defineComponent({
    name: "TelegramCanvas",
    components: {
        TelegramHeader,
        TelegramBody,
        TelegramFooter
    },
    methods: {
        watchCanvasOpening() {
            const TelegramHistoryCanvas = document.getElementById('TelegramHistoryCanvas')
            TelegramHistoryCanvas.addEventListener('shown.bs.offcanvas', this.onCanvasOpen)
        },
        onCanvasOpen() {
            this.$refs.telegramBody.asyncGetMessages()
        },
    },
    mounted() {
        this.watchCanvasOpening()
    },
})
</script>

<template>
    <div class="offcanvas offcanvas-end chat-background ps-0" tabindex="-1"
         id="TelegramHistoryCanvas" style="min-width: 450px"
         aria-labelledby="TelegramHistoryCanvasLabel"
    >
        <TelegramHeader/>
        <TelegramBody ref="telegramBody"/>
        <TelegramFooter/>
    </div>
</template>

<style lang="scss">
.telegram-message {
  position: relative;
  border-radius: 17px 17px 10px 10px !important;
}

.telegram-message-footer button {
  border-radius: 10px 10px 17px 17px !important;
  padding: 12px 0;
  background-color: rgba(33, 37, 41, 0.4);
  width: 100%;
  color: white !important;
}

.chat-background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.telegram-message-wrapper {
  overflow-y: scroll;
  scrollbar-width: thin;
  scroll-behavior: smooth;
}

.telegram-message-wrapper::-webkit-scrollbar {
  width: 8px;
  background-color: rgba(117, 96, 96, 0.38);
}

.telegram-message-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: #333333;
}

[data-layout-mode="dark"] {
  .telegram-message::after {
    border-color: transparent #212529 transparent transparent;
  }

  .chat-background {
    background-image: url("https://i.pinimg.com/originals/f6/c1/0a/f6c10a01b8f7c3285fc660b0b0664e52.jpg");
  }

  .telegram-message-wrapper::-webkit-scrollbar-thumb {
    background-color: #000000;
  }

  .telegram-message-footer button {
    background-color: rgba(33, 37, 41, 0.8);
  }
}

[data-layout-mode="light"] {
  .telegram-message::after {
    border-color: transparent #fff transparent transparent;
  }

  .chat-background {
    background-image: url("https://i.pinimg.com/originals/6e/95/f5/6e95f570a15c71e5f184106a63d847df.jpg");
  }

  .telegram-message-wrapper::-webkit-scrollbar-thumb {
    background-color: #f3f3f3;
  }
}

.message-time-received {
  position: absolute;
  bottom: 5px;
  right: 15px;
}

.message-time-received span {
  font-size: 0.8rem;
}

.offcanvas-backdrop.show {
  opacity: 1;
  background-color: rgb(8, 15, 21, 0.6);
  backdrop-filter: blur(3px);
  transition: 500ms;
}

.offcanvas-backdrop {
  opacity: 0;
  transition: 500ms;
}

</style>