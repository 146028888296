<script>
import axios from "axios";
import SwiperCore, {Navigation, Pagination, Thumbs} from "swiper";
import {Swiper, SwiperSlide} from "swiper/vue";
import "swiper/swiper-bundle.css";

SwiperCore.use([Thumbs, Pagination, Navigation]);
import DispatchContainers from "@/views/pages/terminal/models/DispatchContainers.vue";

export default {
  name: "ExpectedTrainsStatistics",
  components: {
    Swiper,
    SwiperSlide,
    DispatchContainers
  },
  data() {
    return {
      trains: []
    };
  },
  methods: {
    async getTrains() {
      try {
        let {data} = await axios.get("/terminal/containers_grouped_by_lot_number/");
        this.trains = data.map((train) => {
          return {
            lot_number: train.lot_number || "-",
            total_documents: train.total_documents || 0,
            total_seal_images: train.total_seal_images || 0,
            total_count: (train.total_documents || 0) + (train.total_seal_images || 0),
            order_numbers: train.order_numbers || []
          }
        })
      } catch {
        console.log("Error while getting trains");
      }
    },
    openDispatchTrainModal(train) {
      if (train) {
        let lot_number = train.lot_number;
        if (lot_number[0] === 'T' && lot_number[1] === '-') {
          lot_number = lot_number.slice(2);
        }
        this.$refs.dispatchContainers.openModal([], lot_number);
      } else {
        this.$refs.dispatchContainers.openModal();
      }
    }
  },
  mounted() {
    this.getTrains();
  }
}
</script>

<template>
  <DispatchContainers ref="dispatchContainers"/>

  <swiper
      class="default-swiper rounded"
      :loop="false"
      :grabCursor="true"
      :slidesPerView="5"
      :breakpoints="{
                640: {
                  slidesPerView: 1.5,
                },
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
                1200: {
                  slidesPerView: 4,
                },
                1400: {
                  slidesPerView: 5,
                }
              }"
      :spaceBetween="15"
  >
    <swiper-slide v-for="(train, index) in trains" :key="`train_${index}`">
      <b-card>
        <div class="d-flex justify-content-between align-items-start gap-3 flex-wrap mb-3">
          <div v-b-tooltip.hover title="Train" class="d-flex align-items-center gap-2">
            <img src="@/assets/images/train.webp" class="img-fluid" style="height: 25px; width: 25px" alt="Train icon">
            <h5 class="fs-15 fw-semibold mb-0">{{ train.lot_number }}</h5>
          </div>
          <div class="d-flex gap-2">
            <template v-for="order_number in (train.order_numbers || [])" :key="`train_${index}_${order_number}`">
              <router-link :to="{name: 'orders_container_detail', params: { id: order_number }}">
              <span class="badge badge-soft-success">
              {{ order_number }}
            </span>
              </router-link>
            </template>

          </div>
        </div>
        <div class="row flex-wrap align-items-center gy-3">
          <div class="col-6">
            <div class="d-flex flex-column border rounded-3 px-2 py-1 text-truncate w-100" v-b-tooltip.hover
                 title="Train Documents">
              <div class="d-flex align-items-center gap-1">
                <i class="bx bx-file fs-18 text-primary align-middle"></i>
                <span class="fs-5">{{ train.total_documents }}</span>
              </div>
              <small class="text-muted text-nowrap text-truncate">Documents</small>
            </div>
          </div>
          <div class="col-6">
            <div class="d-flex flex-column border rounded-3 px-2 py-1 text-truncate w-100" v-b-tooltip.hover
                 title="Train Seal Images">
              <div class="d-flex align-items-center gap-1">
                <i class="bx bx-image fs-18 text-success align-middle"></i>
                <span class="fs-5">{{ train.total_seal_images }}</span>
              </div>
              <small class="text-muted text-nowrap text-truncate">Seal images</small>
            </div>
          </div>
          <div class="col-12">
            <b-button @click="openDispatchTrainModal(train)" variant="outline-success"
                      size="sm" class="w-100">Dispatch Train
            </b-button>
          </div>
        </div>
      </b-card>
    </swiper-slide>
  </swiper>
</template>

<style scoped>

</style>