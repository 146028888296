<script>
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import NoData from "@/components/custom/NoData.vue";
import ExpenseAmountCreate from "@/views/pages/terminal/models/ExpenseAmountCreate.vue";
import ExpenseAmountUpdate from "@/views/pages/terminal/models/ExpenseAmountUpdate.vue";

export default {
  name: "SeeExpenses",
  emits: ['add_expense'],
  props: {
    terminal: {
      type: Object,
      required: true
    }
  },
  components: {
    NoData,
    ExpenseAmountCreate,
    ExpenseAmountUpdate,
  },
  data() {
    return {
      expenses: [],
      container_types: ['20', '20HC', '40', '40HC', '45'],
      is_loading_expenses: false,
      show_amount_update_model: false
    }
  },
  methods: {
    async getExpenses() {
      try {
        this.is_loading_expenses = true;
        let response = await axios.get(`/terminal/expanse/list/${this.terminal.id}/`);
        this.expenses = response.data.map(expense => {
          return {
            id: expense.id,
            name: expense.name,
            expanse_type: expense.expanse_type,
            description: expense.description,
            amounts: (expense.terminal_expanse_amounts || []).map(amount => {
              return {
                id: amount.id,
                amount: amount.amount || 0,
                validation_date: amount.validation_date,
                container_type: amount.container_type || '',
                is_laden: amount.is_laden || false,
              }
            }),

            is_being_edited: false,
            delete_confirmed: false,
            is_being_deleted: false,
            is_deleted: false,
          }
        })
        this.validateExpenses()
      } catch {
        this.expenses = []
      }
      this.is_loading_expenses = false;
    },
    addNewExpense() {
      this.$emit('add_expense', this.terminal)
    },
    async updateExpense(expense) {
      if (expense.is_being_edited) return;
      let Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
      })
      try {
        expense.is_being_edited = true;
        await axios.put(`/terminal/expanse/${expense.id}/update/`, {
          name: expense.name,
          expanse_type: expense.expanse_type,
          description: expense.description,
          terminal_id: this.terminal.id,
        })
        expense.is_being_edited = false;
        await Toast.fire({
          icon: "success",
          title: "Expense updated successfully",
        })
      } catch {
        expense.is_being_edited = false;
        await Toast.fire({
          icon: "error",
          title: "Error updating expense",
        })
      }
    },
    async deleteExpense(expense) {
      let Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
      })
      expense.is_being_deleted = true;
      try {
        await axios.delete(`/terminal/expanse/${expense.id}/delete/`)
        expense.is_deleted = true;
        await this.getExpenses()
        await Toast.fire({
          icon: "success",
          title: "Expense deleted successfully",
        })
      } catch {
        expense.is_being_deleted = false;
        expense.is_deleted = false;
        await Toast.fire({
          icon: "error",
          title: "Error deleting expense",
        })
      }
    },

    validateExpenses() {
      this.expenses.forEach(expense => {
        expense.has_duplicate_container_types = expense.amounts.some((amount, index) => {
          return expense.amounts.findIndex(a => a.container_type === amount.container_type) !== index
        })
      })
    },
    isDuplicateAmount(expense, amount) {
      return expense.amounts.filter((e) => {
        return (e.container_type === amount.container_type && parseFloat(e.amount || 0) === parseFloat(amount.amount || 0) && e.validation_date === amount.validation_date)
            || (e.container_type === amount.container_type && e.validation_date === amount.validation_date)
      }).length > 1
    },

    formatDate(date) {
      return moment(date).format('ll')
    },

    async deleteConfirmation(expense) {
      await Swal.fire({
        icon: "error",
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        confirmButtonColor: "#f06548",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.deleteExpense(expense)
        }
      });
    },
  },

  watch: {
    terminal: {
      handler: function () {
        this.getExpenses()
      },
      deep: true,
      immediate: true
    },
    expenses: {
      handler: function () {
        this.validateExpenses()
      },
      deep: true
    }
  },
}
</script>

<template>

  <div class="d-flex justify-content-between mb-2">
    <nav aria-label="breadcrumb">
      <b-breadcrumb>
        <b-breadcrumb-item active>
          <span class="text-success fw-semibold">{{ terminal.name }}</span>
        </b-breadcrumb-item>
        <b-breadcrumb-item active>View Expenses</b-breadcrumb-item>
      </b-breadcrumb>
    </nav>
    <h6 @click="addNewExpense()"
        class="cursor-pointer text-success mb-0">
      Add a new expense
      <i class="mdi mdi-chevron-right align-middle fs-4"></i>
    </h6>
  </div>

  <b-row class="gy-4" v-if="!is_loading_expenses">
    <template v-if="expenses.length > 0">
      <b-col lg="4" v-for="(expense, index) in expenses" :key="`expense_${expense}_${index}`">
        <b-card no-body>
          <div class="card-header bg-soft-success pb-1" role="button">
            <form @submit.prevent="updateExpense(expense)"
                  class="d-flex justify-content-between align-items-center gap-2 mb-2">
              <div>
                <input v-model="expense.name" class="form-control form-control-sm outline-none border-0"
                       placeholder="Expense name">
              </div>
              <div>
                <select v-model="expense.expanse_type" class="form-select form-select-sm">
                  <option value="daily">Daily</option>
                  <option value="one_time">One time</option>
                </select>
              </div>
              <div>
                <b-button type="submit" variant="success" size="sm">
                  <i class="mdi mdi-check-bold"></i>
                </b-button>
              </div>
              <div>
                <b-button @click="deleteConfirmation(expense)" type="button" variant="danger" size="sm">
                  <i class="bx bxs-trash-alt align-middle"></i>
                </b-button>
              </div>
            </form>
            <div>
              <div>
                <button :data-bs-target="`#expense${expense.id}`"
                        data-bs-toggle="collapse" class="btn btn-sm w-100">
                  <i class="mdi mdi-chevron-down"></i>
                </button>
              </div>
            </div>
          </div>
        </b-card>
        <b-collapse :id="`expense${expense.id}`" visible data-simplebar
                    style="max-height: 535px;">

          <ExpenseAmountCreate :expense="expense" @expenseAmountCreated="this.getExpenses()"/>

          <template v-for="(amount, amount_index) in (expense.amounts)" :key="`amount${amount.id}`">
            <b-card
                @mouseover="amount.hovered = true"
                @mouseleave="amount.hovered = false"
                no-body class="mb-1 shadow-none border"
                :class="{
                  'border-success': amount.hovered,
                }"
            >
              <b-card-body class="">
                <div class="d-flex align-items-center" role="button"
                     :data-bs-target="`#expense${expense.id}_amount_${amount_index + 1}`" data-bs-toggle="collapse">
                  <div class="flex-grow-1">
                    <div class="d-flex justify-content-between mb-2">
                      <h6 v-b-tooltip.hover title="Container type" class="fs-14">
                        <span class="badge badge-soft-success">
                          {{
                            amount.container_type
                          }}
                        </span>
                      </h6>
                      <p v-b-tooltip.hover
                         :title="`Charged ${expense.expanse_type === 'daily' ? 'Daily' : 'One time'} `"
                         class="text-muted mb-0"
                      >
                        {{ expense.expanse_type === 'daily' ? 'Daily' : 'One time' }}
                        <i :class="{
                          'mdi mdi-check-circle-outline': expense.expanse_type === 'one_time',
                          'mdi mdi-calendar-clock': expense.expanse_type === 'daily',
                        }" class="fs-5 align-middle"></i>
                      </p>
                    </div>
                    <div class="d-flex justify-content-between flex-wrap">
                      <p class="text-dark mb-0">
                        {{
                          parseFloat(amount.amount || 0).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })
                        }}
                        <small class="text-muted">
                          {{ expense.expanse_type === 'daily' ? 'per day' : 'one time' }}
                        </small>
                      </p>
                      <p class="text-dark mb-0">
                        <small class="text-muted">valid until</small>
                        {{ formatDate(amount.validation_date) }}
                      </p>
                    </div>
                  </div>
                </div>
              </b-card-body>
              <b-collapse
                  class="border-top border-top-dashed" :id="`expense${expense.id}_amount_${amount_index + 1}`">
                <ExpenseAmountUpdate
                    @expenseAmountDeleted="this.getExpenses()"
                    :amount="amount" :expense="expense"
                />
              </b-collapse>
            </b-card>
          </template>
        </b-collapse>
      </b-col>

    </template>
    <b-col v-else class="text-center">
      <NoData>
        <h4 class="mb-3">
          No expenses found
        </h4>
        <b-button variant="success" @click="addNewExpense()">
          Add New Expense
        </b-button>
      </NoData>
    </b-col>
  </b-row>
  <b-row class="gy-4" v-else-if="is_loading_expenses">
    <b-col sm="12" class="text-center py-5 my-5">
      <b-spinner variant="success"></b-spinner>
    </b-col>
  </b-row>
</template>

<style scoped>

</style>