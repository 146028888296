<script>
import axios from "axios";
import TerminalSettings from "@/views/pages/terminal/models/TerminalSettings.vue";
import TerminalUpdate from "@/views/pages/terminal/models/TerminalUpdate.vue";
import skeleton from "@/components/custom/skeleton.vue";
import Swal from "sweetalert2";

export default {
  components: {
    TerminalSettings,
    TerminalUpdate,
    skeleton
  },
  emits: ['containersSyncedWithExpenses'],
  data() {
    return {
      terminals: [],
      is_loading: true,
      error: false,
      show_terminal_settings_modal: false,
      current_terminal: {}
    };
  },
  methods: {
    async getTerminals() {
      this.is_loading = true;
      let response = await axios.get('/terminal/containers/count/');
      if (response.status === 200) {
        this.terminals = response.data.map(terminal => (
            {
              id: terminal.id,
              name: terminal.name,
              count: terminal.count || 0,
              free_days: terminal.free_days || 0,
              available: terminal.available || 0,
              pending: terminal.pending || 0,
              dispatched: terminal.dispatched || 0,
              documents: terminal.documents,
              seal_images: terminal.seal_images,
              total_storage_cost: terminal.total_storage_cost || 0,
              total_additional_cost: terminal.total_additional_cost || 0,
            }
        ))
      } else {
        this.error = true;
        this.terminals = []
      }
      this.is_loading = false;
    },
    async syncTerminalExpenses(terminal) {
      await Swal.fire({
        title: 'Sync Terminal Expenses',
        text: 'Are you sure you want to sync terminal expenses?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            terminal.syncing = true;
            await axios.post(`/terminal/terminal_container_cost_calculate/`, {
              terminal_id: terminal.id
            })
            terminal.syncing = false
            this.$emit('containersSyncedWithExpenses')
            await Swal.fire({
              title: 'Success',
              text: 'Terminal expenses synced successfully',
              icon: 'success',
              confirmButtonText: 'Ok',
            })
          } catch {
            terminal.syncing = false
            await Swal.fire({
              title: 'Error',
              text: 'An error occurred while syncing terminal expenses',
              icon: 'error',
              confirmButtonText: 'Ok',
            })
          }
        }
      })
    },
    editTerminal(terminal) {
      this.current_terminal = terminal;
      this.show_terminal_settings_modal = true;
    },
    async showContainersByTerminal(terminal, container_status = null) {
      let query = {
        terminal: terminal.name,
      }
      if (container_status) {
        query.container_status = container_status
      }
      await this.$router.push({
        name: 'terminal',
        query: query
      })
    },
    calculateTotalCosts(terminal) {
      return parseFloat(terminal.total_storage_cost || 0) + parseFloat(terminal.total_additional_cost || 0)
    }
  },
  async mounted() {
    await this.getTerminals()
  }
};
</script>

<template>
  <TerminalSettings ref="terminalSettings"/>
  <TerminalUpdate ref="terminalUpdate"/>

  <template v-if="!is_loading">
    <div class="col-12 col-lg-6 col-xl-4"
         v-for="terminal in terminals"
         :key="`terminal_${terminal.id}`"
    >
      <div class="card">
        <div class="card-body"><!----><!---->

          <div class="p-3 mt-n3 mx-n3 bg-danger-subtle rounded-top bg-soft-success">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1">
                <div class="d-flex align-items-center gap-3 ">
                  <div class="dropdown">
                    <button
                        type="button"
                        class="btn btn-icon btn-lg btn-ghost-secondary bg-soft-success"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                      <i class="ri-settings-3-fill text-success fs-3"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-start">
                      <!-- item-->
                      <a
                          href="javascript:void(0)"
                          class="dropdown-item notify-item language py-2"
                          data-lang="en"
                          title="English"
                          @click="this.$refs.terminalSettings.addExpense(terminal)"
                      >
                        <div class="d-flex gap-2 align-items-center">
                          <i class="mdi mdi-plus"></i>
                          <span class="align-middle">Add Expense</span>
                        </div>
                      </a>
                      <a
                          href="javascript:void(0)"
                          class="dropdown-item notify-item language py-2"
                          data-lang="en"
                          title="English"
                          @click="this.$refs.terminalSettings.getExpenses(terminal)"
                      >
                        <div class="d-flex gap-2 align-items-center">
                          <i class="mdi mdi-eye"></i>
                          <span class="align-middle">View Expenses</span>
                        </div>
                      </a>
                      <a
                          href="javascript:void(0)"
                          class="dropdown-item notify-item language py-2"
                          data-lang="en"
                          title="English"
                          @click="this.$refs.terminalUpdate.openModal(terminal)"
                      >
                        <div class="d-flex gap-2 align-items-center">
                          <i class="bx bxs-pencil"></i>
                          <span class="align-middle">
                            Edit Terminal
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between w-100">
                    <div>
                      <h5 class="mb-1">
                        <a class="text-body fs-5">
                          {{ terminal.name }}
                        </a>
                      </h5>
                      <p v-b-tooltip.hover title="Total storage costs + Additional costs"
                         class="mb-0 text-muted">
                        <i class="ri-information-line align-middle me-1"></i>
                        {{
                          parseFloat(calculateTotalCosts(terminal)).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })
                        }}
                      </p>
                    </div>
                    <div class="text-end">
                      <h5 class="mb-1">
                        <a class="text-body fs-5">
                          {{ terminal.free_days }}
                        </a>
                      </h5>
                      <p v-b-tooltip.hover title="Terminal free days"
                         class="mb-0 text-muted">
                        Free days
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-between pt-3 pb-2 gy-3">
            <div class="text-start">
              <VTooltip>
                <h3 class="cursor-pointer" @click="showContainersByTerminal(terminal, 'available')">
                    <span class="">
                      {{ terminal.available }}
                    </span>
                </h3>
                <template v-slot:popper>
                  <span>Click to see these containers</span>
                </template>
              </VTooltip>
              <p class="text-muted mb-1">Available Containers</p>
            </div>
            <div class="text-end">
              <VTooltip>
                <h3 class="cursor-pointer" @click="showContainersByTerminal(terminal)">
                    <span class="text-muted">
                      {{ terminal.count }}
                    </span>
                </h3>
                <template v-slot:popper>
                  <span>Click to see these containers</span>
                </template>
              </VTooltip>
              <p class="text-muted mb-1">Total Containers</p>
            </div>
          </div>

          <div class="d-flex flex-row flex-wrap justify-content-between">
            <div class="d-flex gap-2 flex-wrap">
              <VTooltip>
                <button @click="showContainersByTerminal(terminal, 'available')"
                        class="btn waves-effect fw-bold waves-light btn-sm"
                        :class="{
                  'btn-secondary': this.$route.query.terminal === terminal.name && this.$route.query.container_status === 'available',
                  'btn-soft-secondary': this.$route.query.terminal !== terminal.name || this.$route.query.container_status !== 'available',
                        }"
                >
                  {{ terminal.available }}
                </button>
                <template v-slot:popper>
                  <h5 class="text-light mb-0">Available containers' quantity</h5>
                </template>
              </VTooltip>
              <VTooltip>
                <button @click="showContainersByTerminal(terminal, 'pending')"
                        class="btn waves-effect fw-bold waves-light btn-sm"
                        :class="{
                  'btn-warning': this.$route.query.terminal === terminal.name && this.$route.query.container_status === 'pending',
                  'btn-soft-warning': this.$route.query.terminal !== terminal.name || this.$route.query.container_status !== 'pending',
                        }"
                >
                  {{ terminal.pending }}
                </button>
                <template v-slot:popper>
                  <h5 class="text-light mb-0">Pending containers' quantity</h5>
                </template>
              </VTooltip>
              <VTooltip>
                <button @click="showContainersByTerminal(terminal, 'dispatched')"
                        class="btn waves-effect fw-bold waves-light btn-sm"
                        :class="{
                  'btn-success': this.$route.query.terminal === terminal.name && this.$route.query.container_status === 'dispatched',
                  'btn-soft-success': this.$route.query.terminal !== terminal.name || this.$route.query.container_status !== 'dispatched',
                        }"
                >
                  {{ terminal.dispatched }}
                </button>
                <template v-slot:popper>
                  <h5 class="text-light mb-0">Dispatched containers' quantity</h5>
                </template>
              </VTooltip>
            </div>
            <div class="d-flex gap-2 flex-wrap">
              <VTooltip>
                <h4>
                  <b-badge variant="light">
                    <i class="mdi mdi-image-outline text-warning me-1"></i>
                    {{ terminal.seal_images }}
                  </b-badge>
                </h4>
                <template v-slot:popper>
                  <h5 class="text-light mb-0">Containers' images quantity</h5>
                </template>
              </VTooltip>
              <VTooltip>
                <h4>
                  <b-badge variant="light">
                    <i class="mdi mdi-microsoft-word text-secondary me-1"></i>
                    {{ terminal.documents }}
                  </b-badge>
                </h4>
                <template v-slot:popper>
                  <h5 class="text-light mb-0">Containers' documents quantity</h5>
                </template>
              </VTooltip>
            </div>
          </div>

          <div class="border p-2 mt-3 rounded">
            <div class=" d-flex flex-wrap gap-3 justify-content-between mb-2">
              <div>
                <h5>
                <span>{{
                    parseFloat(terminal.total_storage_cost).toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })
                  }}
              </span>
                </h5>
                <span class="text-muted">Total storage costs</span>
              </div>
              <div class="text-end">
                <h5>
                  <span>{{
                      parseFloat(terminal.total_additional_cost).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })
                    }}
                  </span>
                </h5>
                <span class="text-muted">Additional costs</span>
              </div>
            </div>
            <div>
              <b-button variant="soft-success" @click="syncTerminalExpenses(terminal)" class="w-100"
                        :disabled="terminal.syncing">
                <i class="me-1" :class="{
                  'mdi mdi-spin mdi-loading': terminal.syncing,
                  'ri-repeat-2-fill align-middle': !terminal.syncing
                }"></i>
                {{ terminal.syncing ? 'Syncing...' : 'Sync Terminal Expenses' }}
              </b-button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="col-12 col-lg-6 col-xl-4"
         v-for="terminal in 3"
         :key="`terminal_loading_${terminal}`"
    >
      <div class="card">
        <div class="card-body pb-0"><!----><!---->
          <div class="p-3 mt-n3 mx-n3 bg-danger-subtle rounded-top bg-soft-info">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1">
                <div class="d-flex gap-3 ">
                  <div class="dropdown">
                    <button
                        type="button"
                        class="btn btn-icon btn-lg btn-ghost-secondary bg-soft-success"
                    >
                      <i class="ri-settings-3-fill text-success fs-3"></i>
                    </button>
                  </div>
                  <div class="pt-1">
                    <h5 class="mb-1">
                      <a class="text-body">
                        <skeleton class_list="py-2"/>
                      </a>
                    </h5>
                    <p class="text-muted mb-0">
                      Terminal name
                    </p>
                  </div>
                </div>
              </div>
              <div class="flex-shrink-0 text-end">
                <div class="d-flex gap-3">
                  <div>
                    <h5 class="mb-1">
                      <a class="text-muted fs-5">
                        0 days
                      </a>
                    </h5>
                    <p class="text-muted mb-0">
                      Free storage
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row pt-3 pb-2 gy-3">
            <div class="col-12">
              <div>
                <p class="text-muted mb-1">Containers Count</p>
                <div>
                  <h3><span class="text-muted">0</span></h3>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-row flex-wrap justify-content-between pb-2">
            <div class="d-flex gap-2 flex-wrap pb-1">
              <skeleton class_list="btn btn-success border-0"/>
              <skeleton class_list="btn btn-success border-0"/>
              <skeleton class_list="btn btn-success border-0"/>
            </div>
            <div class="d-flex gap-2 flex-wrap">
              <skeleton class_list="btn btn-success border-0"/>
              <skeleton class_list="btn btn-success border-0"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>
