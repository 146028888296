<script>
import {defineComponent} from 'vue'
import TelegramCanvas from "@/components/telegram/TelegramCanvas.vue";

export default defineComponent({
    name: "TelegramHistory",
    components: {
        TelegramCanvas
    }
})
</script>

<template>
    <TelegramCanvas/>
</template>

