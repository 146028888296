<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "TelegramHeader"
})
</script>

<template>
    <div class="offcanvas-header p-0">
        <b-card no-body class="w-100 my-0 rounded-0">
            <b-card-body class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                    <i data-bs-dismiss="offcanvas"
                       aria-label="Close" class="bx bx-arrow-from-right cursor-pointer fs-2 ms-2 me-4"></i>
                    <div>
                        <h5 class="offcanvas-title fw-bold" id="TelegramHistoryCanvasLabel">
                            InterRail
                        </h5>
                        <span class="text-muted">bot</span>
                    </div>
                </div>
                <div>

                    <div class="dropdown">
                        <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="bx bx-search fs-2 text-muted align-middle"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end px-2 bg-light" style="min-width: 300px">
                            <input class="form-control border-0 px-2 fs-5 bg-light" type="text" placeholder="Search..">
                        </div>
                    </div>
                </div>
            </b-card-body>
        </b-card>
    </div>
</template>

<style scoped>

</style>