<script>

import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "TerminalSettings",
  props: {
    terminal: {
      type: Object,
      required: true
    }
  },
  emits: ['view_expenses'],
  data() {
    return {
      current_terminal: {},
      form: {
        expense_name: '',
        expense_type: 'daily',
        description: '',
      },
      is_submitting: false
    }
  },
  methods: {
    clearForm() {
      this.form = {
        expense_name: '',
        expense_type: 'daily',
        description: '',
      }
    },
    async addExpense() {
      if (this.is_submitting) return;
      let Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
      })
      if (this.form.expense_name.trim().length === 0 || !this.form.expense_type) {
        await Toast.fire({
          icon: "error",
          title: "Please fill all fields",
        })
        return;
      }
      try {
        this.is_submitting = true;
        await axios.post('/terminal/expanse/create/', {
          name: this.form.expense_name,
          description: this.form.description,
          terminal_id: this.terminal.id,
          expanse_type: this.form.expense_type
        })
        this.clearForm()
        this.viewExpenses()
        await Toast.fire({
          icon: "success",
          title: "Expense added successfully",
        })
      } catch {
        await Toast.fire({
          icon: "error",
          title: "An error occurred",
        })
      }
      this.is_submitting = false;
    },
    viewExpenses() {
      this.$emit('view_expenses')
    }
  },
  watch: {
    terminal: {
      handler: function () {
        this.clearForm()
      },
      deep: true
    }
  }
}
</script>

<template>
  <form @submit.prevent="addExpense()">

    <nav aria-label="breadcrumb">
      <b-breadcrumb>
        <b-breadcrumb-item active>
          <span @click="viewExpenses()" class="cursor-pointer text-success fw-semibold">{{ terminal.name }}</span>
        </b-breadcrumb-item>
        <b-breadcrumb-item active>Add Expense</b-breadcrumb-item>
      </b-breadcrumb>
    </nav>

    <b-row class="gy-3">
      <b-col sm="12">
        <label class="form-label">
          Expense Name
          <span class="text-danger">*</span>
        </label>
        <input maxlength="40" v-model="form.expense_name" class="form-control" placeholder="Enter expense name">
      </b-col>
      <b-col sm="12">
        <label class="form-label">
          Charge
          <span class="text-danger">*</span>
        </label>
        <select v-model="form.expense_type" class="form-select">
          <option value="daily">Daily</option>
          <option value="one_time">One time</option>
        </select>
        <small v-if="form.expense_type === 'daily'" class="text-muted d-block mt-2">
          A daily billing cycle will apply to containers within the
          <small class="text-success fw-medium">{{ terminal.name }}</small> terminal
        </small>
      </b-col>
      <b-col sm="12">
        <label class="form-label">
          Description
          <span class="text-muted">(optional)</span>
        </label>
        <textarea v-model="form.description" class="form-control" rows="3" placeholder="Enter description"></textarea>
      </b-col>
      <b-col sm="12">
        <b-button
            type="submit" variant="success"
            class="w-100 mb-2"
            :disabled="is_submitting || (this.form.expense_name.trim().length === 0 || !this.form.expense_type)">
          {{ is_submitting ? 'Please wait...' : 'Confirm & Submit' }}
        </b-button>
        <b-button @click="viewExpenses()"
                  type="button" variant="light"
                  class="w-100">
          View Expenses
          <span class="text-success fw-medium">({{ terminal.name }})</span>
        </b-button>
      </b-col>
    </b-row>
  </form>
</template>

<style scoped>

</style>