<template>
  <b-modal v-model="showModal" hide-footer class="v-modal-custom p-0" hide-header-close
           centered no-close-on-backdrop size="md" body-class="m-0 p-0">
    <div class="modal-body m-0 p-0 pb-3">

      <swiper class="navigation-swiper rounded" :loop="false" :autoplay="{
              delay: 2500,
              disableOnInteraction: false,
            }" :navigation="{
  nextEl: '.swiper-button-next',
  prevEl: '.swiper-button-prev',
}" :pagination="{
  clickable: true,
  el: '.swiper-pagination',
}">
        <swiper-slide v-for="(img, i) in images" :key="img">

          <div v-if="!deleted_images.includes(img.id)">

            <div class="d-flex justify-content-between flex-wrap px-4 mb-3">
              <h4>Container Seal Image</h4>
              <VTooltip>
                <b-button @click="showModal = false" variant="outline-dark"
                          class="waves-effect waves-light p-1 py-1">
                  <i class="bx bx-x align-middle fs-4"></i>
                </b-button>
                <template v-slot:popper>
                  Click to close the modal
                </template>
              </VTooltip>
            </div>

            <div class="d-flex justify-content-between flex-wrap px-4 mb-3">
              <span class="d-flex align-items-center fw-medium fs-5">
                {{ img.image.split('/seal_images/')[1] }}
              </span>
              <span class="fs-5 fw-medium">{{ i + 1 }} of {{ images.length }}</span>
            </div>

            <div class="d-flex justify-content-between align-items-center px-4 mb-3">
              <i @click="downloadImg(img.image)"
                 class="las la-download cursor-pointer align-middle text-success fs-3"></i>
              <i v-if="!img.delete_confirmed"
                 @click="img.delete_confirmed = true"
                 class="las la-trash-alt text-danger cursor-pointer fs-2"></i>
              <b-button-group v-if="img.delete_confirmed" role="group" aria-label="Basic example">
                <b-button @click="img.delete_confirmed = false" variant="light">Cancel</b-button>
                <b-button @click="deleteImage(img.id)" variant="danger">Delete</b-button>
              </b-button-group>
            </div>

            <div class="container-seal-img mb-3"
                 :style="{backgroundImage: `url(${getImagePath(img.image)})`}">
            </div>
          </div>

          <div v-else class="text-center">
            <lottie
                colors="primary:#405189,secondary:#08a88a"
                :options="no_img_widget"
                :height="100"
                :width="100"
            />
            <h6 class="mb-0 mt-3 text-danger">This Image Has Been Deleted and No Longer Exists!</h6>
          </div>
        </swiper-slide>

        <div class="swiper-button-next bg-soft-dark"></div>
        <div class="swiper-button-prev bg-soft-dark"></div>
        <div class="swiper-pagination"></div>
      </swiper>
    </div>
  </b-modal>

  <div class="modal fade" id="ContainerImgView"
       tabindex="-1" aria-labelledby="ContainerImgViewLabels"
       aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header p-3">
          <h5 class="modal-title" id="ContainerImgViewLabels">
            Container Seal Image
            <span class="badge bg-primary">{{ images.length }}</span>
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" v-if="has_images">

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwiperCore, {Navigation, Pagination, Thumbs} from "swiper";
import {Swiper, SwiperSlide} from "swiper/vue";
import "swiper/swiper-bundle.css";

SwiperCore.use([Thumbs, Pagination, Navigation]);
import axios from "axios";


import spxnqpau from "@/components/widgets/spxnqpau.json";
import Lottie from "@/components/widgets/lottie.vue";

export default {
  emits: ['imgDeleted'],
  name: "container_img_view",
  data() {
    return {
      showModal: false,
      has_images: false,
      deleted_images: [],
      no_img_widget: {animationData: spxnqpau},
      img_delete_confirmed: false,

      container_id: null,
      images: []
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    lottie: Lottie,
  },
  methods: {
    showImages(container_id, container_images) {
      this.container_id = container_id
      this.images = container_images
      this.showModal = true
    },
    downloadImg(filepath) {
      let url = process.env.VUE_APP_ORDER_URL + filepath;
      fetch(url)
          .then((resp) => resp.blob())
          .then((blobobject) => {
            const blob = window.URL.createObjectURL(blobobject);
            const anchor = document.createElement("a");
            anchor.style.display = "none";
            anchor.href = blob;
            anchor.download = url.toString().split("/")[
            url.toString().split("/").length - 1
                ];
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(blob);
            this.is_downloading_file = false;
          })
          .catch(() => alert("An error in downloading the file sorry"));
    },
    getImagePath(image) {
      return `${process.env.VUE_APP_ORDER_URL}${image}`;
    },
    async deleteImage(img_id) {
      let response = await axios.delete(`/terminal/containers/image/${img_id}/delete/`)
      if (response.status === 204) {
        this.deleted_images.push(img_id)
        this.$emit('imgDeleted')
      } else {
        alert('An error has occured !')
      }
    },
  },
  watch: {
    images() {
      this.has_images = false
      setTimeout(() => {
        this.has_images = true
      }, 100)
    },
  }
}
</script>

<style scoped>
.container-seal-img {
  min-height: 480px;
  height: 600px;
  max-height: 1000px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
</style>