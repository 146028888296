<template>
  <b-modal v-model="show_modal" hide-footer title="Container Delete" modal-class="zoomIn"
           class="v-modal-custom" centered>

    <form class="form row" v-if="container" @submit.prevent="deleteContainer()">
      <div class="mb-3">
        <label class="form-label d-block mb-3">Are you sure you want to delete this container?</label>

        <h4 class="text-danger">{{ container.name }}</h4>
      </div>
      <div class="text-end">
        <button v-if="deleting" type="button" class="btn btn-danger disabled btn-load" disabled>
          <span class="d-flex align-items-center">
            <span class="flex-grow-1 me-2">
                                    Deleting...
            </span>
            <span class="spinner-grow flex-shrink-0" role="status">
              <span class="visually-hidden">Deleting...</span>
            </span>
          </span>
        </button>
        <b-button v-else type="submit" variant="danger">
          Yes, Delete
        </b-button>
      </div>
    </form>

  </b-modal>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  emits: ["update"],
  name: "container_update",
  data() {
    return {
      error: false,
      error_message: '',
      deleting: false,
      container: {},
      show_modal: false,
    }
  },

  methods: {
    showModal(row) {
      if (!row) {
        alert('Container is not defined')
        return
      }
      this.container = {
        row_id: row.id,
        ...row.container
      }
      this.show_modal = true
    },
    async deleteContainer() {
      this.deleting = true
      try {
        await axios.delete(`/terminal/containers/${this.container.row_id}/delete/`)
        this.$emit('update')
        this.show_modal = false
      } catch (e) {
        try {
          this.deleting = false
          await Swal.fire({
            icon: 'error',
            title: e.response.data.message,
            html: Object.entries(e.response.data.extra.fields) ? Object.entries(e.response.data.extra.fields).map(([key, value]) => {
              return `<div>
                                    ${key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, " ")}: ${value}</div>`
            }).join('') : '',
          })
        } catch {
          this.deleting = false
          await Swal.fire({
            icon: 'error',
            title: 'Something went wrong',
            html: 'Please try again',
          })
        }
      }
      this.deleting = false
    },
  },
}
</script>

<style scoped>

</style>