<script>
import axios from "axios";
import moment from "moment";
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";

export default {
  name: "ConnectOwners",
  emits: ['onSuccess'],
  components: {
    Multiselect
  },
  data() {
    return {
      showModal: false,

      date_from: null,
      date_to: null,

      lot_number: null,
      lot_number_options: [],

      process_stage: 'input', // input, loading, success, error,
      error_data: {},

      response: {
        type: 'initial',
        message: '',
        details: ''
      }
    }
  },
  methods: {
    openModal() {
      this.showModal = true
    },
    closeModal() {
      this.showModal = false
    },
    async searchLotNumbers(query) {
        if ((query || '').length > 2) {
        try {
          let response = await axios.get('/container_order/lot_numbers/', {
            search: query
          })
          this.lot_number_options = response.data.lot_numbers.map(lot => {
            return {
              value: lot,
              label: lot
            }
          })
        } catch (err) {
          console.log(err)
        }
      }
    },
    async connectToContainers() {
      if (!this.canSubmitForm) {
        return
      }
      this.response.type = 'loading'
      try {
        let response = await axios.post('/terminal/container_connect_orders/', {
          date_from: moment(this.date_from).format('YYYY-MM-DD'),
          date_to: moment(this.date_to).format('YYYY-MM-DD'),
          lot_number: this.lot_number.label,
        })
        this.response = {
          type: 'success',
          message: 'Successfully connected containers with order',
          details: response.data.data
        }
        this.response.type = 'success'
        this.$emit('onSuccess')
        setTimeout(() => {
          this.resetModal()
        }, 2000)
      } catch (err) {
        try {
          this.response.type = 'error'
          this.response.message = err.response.data.message || 'Something went wrong'
          this.response.details = err.response.data.data
        } catch {
          this.response.type = 'error'
          this.response.message = 'Something went wrong'
          this.response.details = 'Please try again'
        }
        this.response.type = 'error'
      }
    },

    resetModal() {
      this.showModal = false
      setTimeout(() => {
        this.process_stage = 'input'
        this.date_from = null
        this.date_to = null
        this.lot_number = null
        this.response = {
          type: 'initial',
          message: '',
          details: ''
        }
      }, 2500)
    }
  },
  computed: {
    canSubmitForm() {
      return this.lot_number && this.date_from && this.date_to
    },
  },
}
</script>

<template>
  <b-modal
      v-model="showModal" id="connectToOrderModal" modal-class="zoomIn" hide-footer
      header-class="p-3 bg-soft-success" class="v-modal-custom" centered size="lg"
      title="Connect Train to Order"
  >

    <form @submit.prevent="connectToContainers()" :class="{'d-none' : response.type !=='initial'}">
      <!-- Primary Alert -->
      <b-alert dismissible show variant="info" class="alert-additional fade show" role="alert">
        <div class="alert-body">
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          <div class="d-flex">
            <div class="flex-shrink-0 me-3">
              <i class="ri-user-smile-line fs-16 align-middle"></i>
            </div>
            <div class="flex-grow-1">
              <h5 class="alert-heading"> We need your attention !</h5>
              <p class="mb-0"> Exactly input lot number and specify the arrival date range to search for order
                containers </p>
            </div>
          </div>
        </div>
      </b-alert>

      <b-row gutter-y="4">
        <b-col sm="12" lg="6">
          <label class="form-label" for="dispatchContainersNames">
            Lot Number
            <span class="text-danger">*</span>
          </label>
          <Multiselect v-model="lot_number"
                       :options="lot_number_options"
                       @search-change="searchLotNumbers($event)"
                       :searchable="true" :object="true"
          />
        </b-col>
        <b-col sm="12" lg="3">
          <label class="form-label" for="dispatchContainersNames">
            Date From
            <span class="text-danger">*</span>
          </label>
          <input v-model="date_from" class="form-control" type="date">
        </b-col>
        <b-col sm="12" lg="3">
          <label class="form-label" for="dispatchContainersNames">
            Date To
            <span class="text-danger">*</span>
          </label>
          <input v-model="date_to" class="form-control" type="date">
        </b-col>


        <b-col sm="12" class="text-center mt-4">
          <b-button type="submit" variant="success"
                    :disabled="!canSubmitForm"
          >
            Confirm & Connect
          </b-button>
        </b-col>
      </b-row>
    </form>

    <div v-if="response.type === 'loading'">
      <div class="text-center py-5">
        <div class="spinner-border text-success mb-4" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <h3>Connecting to order...</h3>
      </div>
    </div>
    <div v-else-if="response.type ==='success'">
      <div class="text-center py-5">
        <i class="mdi mdi-check-circle-outline text-success mb-4" style="font-size: 5rem"></i>
        <h2 class="my-3 mb-3">
          {{ response.message }}
        </h2>
        <p class="fs-4 mb-4" v-html="response.details"/>
      </div>
    </div>
    <div v-else-if="response.type ==='error'">
      <div class="text-center">
        <i class="mdi mdi-close-circle-outline text-danger" style="font-size: 5rem"></i>
        <h2 class="my-3 mb-3">
          {{ response.message }}
        </h2>
        <p class="fs-4 mb-4" v-html="response.details"/>
        <b-button size="lg" variant="success" @click="response.type = 'initial'">Try Again</b-button>
      </div>
    </div>
  </b-modal>

</template>

<style scoped>

</style>