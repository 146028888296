<script>
import {defineComponent} from 'vue'
import TelegramMessageCard from "@/components/telegram/MessageCard.vue";
import axios from "axios";

export default defineComponent({
    name: "Body",
    components: {
        TelegramMessageCard,
    },
    data() {
        return {
            messages: []
        }
    },
    methods: {
        asyncGetMessages() {
            axios.get('/terminal/bot/application/messages/').then((response) => {
                this.messages = response.data.results
                setTimeout(() => {
                    this.scrollToBottom()
                }, 80)
            })
        },
        scrollToBottom() {
            const messageWrapper = this.$refs.messageWrapper;
            messageWrapper.scrollTop = messageWrapper.scrollHeight;
        },
    },
})
</script>

<template>
    <div class="offcanvas-body d-flex flex-column justify-content-end p-0">
        <div ref="messageWrapper" class="telegram-message-wrapper px-3">
            <TelegramMessageCard v-for="m in messages" :key="m" :message="m.message" :file="m.file"/>
        </div>
    </div>

</template>

