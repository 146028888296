<template>
    <div>
        <Multiselect
                v-model="selected_container"
                :options="containers"
                :searchable="true"
                :loading="isLoading"
                @search-change="asyncFindContainer"
                @input="handleChange"
                :object="true"
        />
    </div>
</template>

<script>
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";

import axios from "axios";

export default {
    emits: ["onSelect"],
    components: {
        Multiselect,
    },
    data() {
        return {
            selected_container: null,
            containers: [],
            isLoading: false,
        };
    },
    props: {
        current_container: {
            type: Object,
            required: false,
        },

    },
    methods: {
        async asyncFindContainer(query) {
            if (query.trim().length <= 0) return;
            this.isLoading = true;
            let response = await axios.get("/core/containers/", {
                params: {
                    name: query.trim()
                }
            });
            if (response.status === 200) {
                let data = response.data.results;
                this.containers = data.map((item) => {
                    return {
                        value: item.id,
                        label: item.name,
                    };
                });
                this.isLoading = false;
            }
        },
        handleChange(val) {
            this.$emit("onSelect", val);
        },
    },
    mounted() {
        if (this.current_container) {
            this.containers = [{
                value: this.current_container.id,
                label: this.current_container.name
            }]
            this.selected_container = this.containers[0]
        }
    },
    watch: {
        current_container(value) {
            let validValue = Object.keys(value).length > 0
            if (validValue) {
                this.containers = [{
                    value: value.id,
                    label: value.name
                }]
                this.selected_container = this.containers[0]
            } else {
                this.containers = []
                this.selected_container = 0
            }
        }
    }
};
</script>

<style scoped></style>
