<template>
  <div class="modal fade" id="ContainerUpdate" tabindex="-1" aria-labelledby="ContainerUpdateLabels"
       aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header p-3">
          <h5 class="modal-title" id="ContainerUpdateLabels">Container Update</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">

          <form class="form row" v-if="data" @submit.prevent="saveContainer">
            <div class="col-12 col-lg-6">
              <div class="mb-3">
                <label class="form-label">Container Name</label>
                <input v-model="data.container.name" type="text" class="form-control"
                       placeholder="Container name">
              </div>
              <div class="mb-3">
                <label class="form-label">Terminal</label>
                <selectTerminal @onSelect="onTerminalSelect"
                                :current_terminal="{id:data.terminal.id,name:data.terminal.name}"/>
              </div>
              <div class="mb-3">
                <label class="form-label">Status</label>
                <select v-model="data.status" class="form-select">
                  <option v-for="status in statuses" :value="status.value" :key="`status_${status.value}`">
                    {{ status.label }}
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <label class="form-label">BL</label>
                <input v-model="data.bl" type="text" class="form-control"
                       placeholder="BL">
              </div>
              <div class="mb-3">
                <div class="form-check">
                  <input id="dataIsLaden" class="form-check-input" type="checkbox"
                         v-model="data.is_laden">
                  <label class="form-check-label my-0" for="dataIsLaden">
                    Is Laden
                  </label>
                </div>
              </div>
              <div class="mb-3">
                <div class="form-check">
                  <input id="clearContainerOrder" class="form-check-input" type="checkbox"
                         v-model="clear_container_order">
                  <label class="form-check-label my-0" for="clearContainerOrder">
                    Clear Container Order
                  </label>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="mb-3">
                <label class="form-label">GU</label>
                <input v-model="data.gu" type="text" class="form-control"
                       placeholder="GU">
              </div>
              <div class="mb-3" v-if="data.train">
                <label class="form-label">Train</label>
                <input v-model="data.train.name" type="text" class="form-control"
                       placeholder="Train name">
              </div>
              <div class="mb-3" v-else>
                <label class="form-label">Train</label>
                <input v-model="new_train_name" type="text" class="form-control"
                       placeholder="Train name">
              </div>
              <div class="mb-3">
                <label class="form-label">Arrival Date</label>
                <input type="date" class="form-control" v-model="data.arrival_date">
              </div>
              <div class="mb-3">
                <label class="form-label">Dispatch Date</label>
                <input type="date" class="form-control" v-model="data.dispatch_date">
              </div>
            </div>
            <div class="col-12 text-end">
              <button v-if="saving" type="button" class="btn btn-success btn-load">
                                <span class="d-flex align-items-center">
                                    <span class="flex-grow-1 me-2">
                                    Saving...
                                    </span>
                                    <span class="spinner-grow flex-shrink-0" role="status">
                                    <span class="visually-hidden">Saving...</span>
                                </span>
                                </span>
              </button>
              <b-button v-else type="submit" variant="success">Save</b-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import selectTerminal from "@/components/custom/SelectTerminal";
import Swal from "sweetalert2";

export default {
  emits: ["update"],
  name: "container_update",
  props: {
    container: {
      type: Object,
      required: true,
      default: () => {
      }
    }
  },
  data() {
    return {
      doc: null,
      new_train_name: '',
      error: false,
      error_message: '',
      saving: false,
      clear_container_order: false,

      statuses: [
        {
          value: 'available',
          label: 'Available'
        },
        {
          value: 'pending',
          label: 'Pending'
        },
        {
          value: 'dispatched',
          label: 'Dispatched'
        },
      ]
    }
  },
  components: {
    selectTerminal,
  },
  computed: {
    data() {
      if (Object.keys(this.container).length > 0) {
        return {
          id: this.container.id,
          container: this.container.container,
          status: this.container.status,
          terminal: this.container.terminal,
          train: this.container.train,
          arrival_date: this.container.arrival_date,
          dispatch_date: this.container.dispatch_date,
          is_laden: this.container.is_laden,
          gu: this.container.gu,
          bl: this.container.bl,
        }
      } else {
        return null
      }
    }
  },
  methods: {
    async saveContainer() {
      this.saving = true
      try {
        await axios.put(`/terminal/containers/${this.data.id}/update/`, {
          container_status: this.data.status,
          terminal_id: this.data.terminal.id,
          train_id: this.data.train,
          is_laden: this.data.is_laden,
          bl: this.data.bl,
          gu: this.data.gu,
          arrival_date: this.data.arrival_date ? this.data.arrival_date : null,
          dispatch_date: this.data.dispatch_date ? this.data.dispatch_date : null,
          clear_order: this.clear_container_order,
          container_name: this.data.container ? this.data.container.name : '',
        })
        this.saving = false
        this.$emit('update')
        document.querySelector('#ContainerUpdate .btn-close').click()
      } catch (e) {
        this.saving = false
        try {
          await Swal.fire({
            icon: 'error',
            title: e.response.data.message || "Something went wrong!",
            html: Object.entries(e.response.data.extra.fields) ? Object.entries(e.response.data.extra.fields).map(([key, value]) => {
              return `<div>
                                    ${key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, " ")}: ${value}</div>`
            }).join('') : '',
          })
        } catch {
          await Swal.fire({
            icon: 'error',
            title: "Something went wrong!",
          })
        }
      }
      this.saving = false
    },
    onTerminalSelect(terminal) {
      this.data.terminal = {
        id: terminal.value,
        name: terminal.label
      }
    }
  },
  watch: {
    data() {
      this.clear_container_order = false
    }
  }
}
</script>

<style scoped>

</style>