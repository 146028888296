<script>
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";

export default {
  name: "ExpenseCreateModal",
  emits: ['expenseAmountCreated'],
  props: {
    expense: {
      type: Object,
      default: () => {
      },
      required: true
    }
  },
  data() {
    return {
      showModal: false,
      amount: {
        container_type: null,
        amount: '',
        is_laden: false,
        validation_date: new Date().toISOString().split('T')[0]
      },
    }
  },
  computed: {
    is_duplicate() {
      if (!this.expense || !this.expense.amounts) return false;
      try {
        return (
                this.expense.amounts.filter((i) => {
                  return i.container_type === this.amount.container_type && parseFloat(i.amount) === parseFloat(this.amount.amount) && i.validation_date === this.amount.validation_date
                }).length === 1
            )
            || this.expense.amounts.filter(i => i.container_type === this.amount.container_type && i.validation_date === this.amount.validation_date).length === 1
      } catch {
        return false
      }
    },
    potential_expense_being_updated() {
      return this.expense.amounts.filter(i => {
        // Convert strings to Date objects for comparison
        const currentDate = new Date(this.amount.validation_date);
        const itemDate = new Date(i.validation_date);

        // Check if item's validation_date is less than or equal to the current validation_date
        return itemDate < currentDate && i.container_type === this.amount.container_type && i.is_laden === this.amount.is_laden
      });
    },
    has_expenses_after_new_expenses_period() {
      return this.expense.amounts.filter(i => {

        const currentDate = new Date(this.amount.validation_date);
        const itemDate = new Date(i.validation_date);

        // Check if item's validation_date is greater than or equal to the current validation_date
        return itemDate >= currentDate && i.container_type === this.amount.container_type && i.is_laden === this.amount.is_laden
      });
    },
    formIsValid() {
      return this.amount.container_type && this.amount.amount >= 0 && this.amount.validation_date
    }
  },
  components: {
    Multiselect
  },
  methods: {
    async createExpenseAmount() {
      if (this.is_duplicate) return;
      let Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
      })
      if (!this.expense.id) {
        await Toast.fire({
          icon: "error",
          title: "Expense not found",
          text: "Please, inform developers about this issue",
        })
        return;
      }
      try {
        await axios.post(`/terminal/terminal_expanse_amount/create/`, {
          terminal_expanse_id: this.expense.id,
          container_type: this.amount.container_type,
          amount: this.amount.amount,
          validation_date: this.amount.validation_date,
          is_laden: this.amount.is_laden
        })
        this.$emit('expenseAmountCreated');
        await Toast.fire({
          icon: "success",
          title: "Expense added",
        })
      } catch (e) {

        try {
          let error_title = e.response.data.message || "Error creating expense";
          let error_text = Object.entries(e.response.data.extra.fields).map(([key, value]) => {
            return `<b>${key.split('_')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')}:</b> ${value.join(', ')} <br>`
          }).join('\n') || "Please, inform developers about this issue";
          await Toast.fire({
            icon: "error",
            title: error_title,
            html: error_text,
          })
        } catch {
          await Toast.fire({
            icon: "error",
            title: "Error creating expense",
            text: "Please, inform developers about this issue",
          })
        }

      }
    },

    formatDate(date) {
      return moment(date).format('ll')
    },
    formatWithOneDayPlus(date) {
      return moment(date).add(1, 'days').format('ll')
    }
  },
  watch: {
    "amount.amount"(newVal) {
      // Check if newVal is NaN, less than 0, or greater than 1 million
      if (isNaN(newVal) || newVal < 0 || newVal > 100000) {
        // If NaN, less than 0, or greater than 1 million, set it to the appropriate limit
        this.amount.amount = isNaN(newVal) || newVal < 0 ? 0 : Math.min(newVal, 100000);
      }
    },

  }
}
</script>

<template>
  <b-card v-if="expense"
          no-body class="mb-1 shadow-none mb-2"
  >
    <div>
      <b-button :data-bs-target="`#expense${expense.id}_amount_create`" data-bs-toggle="collapse"
                variant="soft-success"
                class="w-100" size="sm"
      >
        + Add Expense
      </b-button>
    </div>
    <b-collapse
        class="border-top border-top-dashed" :id="`expense${expense.id}_amount_create`">
      <form @submit.prevent="createExpenseAmount()">
        <b-card-body class="mb-0">

          <p v-if="!is_duplicate" class="text-muted">
            {{ amount.is_laden ? 'Laden' : 'Empty' }} containers with type {{ amount.container_type }}
            will be
            charged at
            {{
              parseFloat(amount.amount || 0).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
            }} {{ amount.expanse_type === 'daily' ? 'per day' : 'one time' }} until
            {{ formatDate(amount.validation_date) }}
          </p>

          <p class="text-secondary" v-if="potential_expense_being_updated.length > 0">
            Starting from {{ formatWithOneDayPlus(potential_expense_being_updated[0].validation_date) }},
            {{ amount.is_laden ? 'laden' : 'empty' }} containers
            with type {{ amount.container_type }} will be charged at
            {{
              parseFloat((amount.amount) || 0).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
            }} {{ amount.expanse_type === 'daily' ? 'per day' : 'one time' }} until
            {{ formatDate(amount.validation_date) }}.

            <span class="text-danger" v-if="has_expenses_after_new_expenses_period.length === 0">
              <br><br> Please, do not forget to add expense for the period starting from {{
                formatWithOneDayPlus(amount.validation_date)
              }}
            </span>
          </p>

          <div class="d-flex flex-column gap-3">
            <!-- Secondary Alert -->
            <b-alert v-if="is_duplicate" show variant="danger" class="border-0 mb-0 mt-2" role="alert">
              <strong> Hey - </strong> This expense already exists
            </b-alert>

            <div>
              <label class="form-label">Container type</label>
              <Multiselect v-model="amount.container_type" :options="['20', '20HC', '40', '40HC', '45']"/>
            </div>
            <div>
              <label class="form-label">
                Price <small class="text-muted">(USD)</small>
              </label>
              <input v-model="amount.amount" class="form-control" placeholder="Enter price" type="number" step="any">
              <small v-if="amount.amount < 0" class="text-danger">Price cannot be less than 0</small>
            </div>
            <div>
              <label class="form-label">
                Valid until
              </label>
              <input v-model="amount.validation_date" class="form-control" type="date">
            </div>
            <div>
              <div class="form-check form-check-outline form-check-success">
                <input v-model="amount.is_laden" class="form-check-input" type="checkbox"
                       :id="`expense${expense.id}_amount_create_is_laden`"
                >
                <label class="form-check-label" :for="`expense${expense.id}_amount_create_is_laden`">
                  Is Laden
                </label>
              </div>
            </div>
          </div>
        </b-card-body>
        <b-card-footer class="hstack gap-2">
          <b-button :disabled="!formIsValid" type="submit" variant="success" size="sm" class="w-100">
            <i class="mdi mdi-check-bold align-bottom me-1"></i> Add
          </b-button>
          <b-button :data-bs-target="`#expense${expense.id}_amount_create`" data-bs-toggle="collapse" variant="light"
                    size="sm" class="w-100" type="button">
            Later
          </b-button>
        </b-card-footer>
      </form>
    </b-collapse>
  </b-card>
</template>

<style scoped>

</style>