<script>
import ConnectOwners from "@/views/pages/terminal/models/ConnectOwners.vue";
import DispatchContainers from "@/views/pages/terminal/models/DispatchContainers.vue";
import AddContainers from "@/views/pages/terminal/models/AddContainers.vue";
import ConnectToOrder from "@/views/pages/terminal/models/ConnectToOrder.vue";

export default {
  name: "TerminalActions",
  emits: ['updateTable'],
  components: {
    AddContainers,
    DispatchContainers,
    ConnectOwners,
    ConnectToOrder
  },
  data() {
    return {
      selected_rows: [],
      has_different_terminal_names: false,
    }
  },
  methods: {
    async updateTable() {
      await this.$emit('updateTable')
    },
    showConnectOwnersModal(rows) {
      let containers = rows.map(row => {
        return {
          ...row,
          id: row.container.id,
          name: row.container.name || '-',
        }
      })
      this.$refs.ownersModal.openModal(containers)
    },
    showAddContainersModal() {
      this.$refs.addContainers.openModal()
    },
    showConnectToOrderModal() {
      this.$refs.connectToOrder.openModal()
    },
    showDispatchContainersModal(rows) {
      if (this.has_different_terminal_names) return;
      let containers = rows.map(row => {
        return {
          id: row.container.id,
          name: row.container.name || '-',
          terminal: row.terminal
        }
      })
      this.$refs.dispatchContainers.openModal(containers)
    },
  }
}
</script>

<template>
  <ConnectOwners ref="ownersModal" @onSuccess="updateTable()"/>
  <DispatchContainers ref="dispatchContainers" @onSuccess="updateTable()"/>
  <AddContainers ref="addContainers" @onSuccess="updateTable()"/>
  <ConnectToOrder ref="connectToOrder" @onSuccess="updateTable()"/>

  <div class="dropdown">
    <button
        type="button"
        class="btn btn-outline-primary"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
    >
      Actions
    </button>

    <div class="dropdown-menu dropdown-menu-end">
      <h6 class="dropdown-header">I WANT TO </h6>
      <a class="dropdown-item py-2"
         @click="showAddContainersModal()"
         style="cursor: pointer">
        <i class="mdi mdi-plus me-1"></i>
        Add Containers
      </a>
      <a class="dropdown-item py-2"
         :class="{
                'disabled': has_different_terminal_names
                 }"
         @click="showDispatchContainersModal(selected_rows)"
         style="cursor: pointer">
        <i class="mdi mdi-check me-1"></i>
        Dispatch Containers
      </a>
      <a class="dropdown-item py-2"
         @click="showConnectToOrderModal(selected_rows)"
         style="cursor: pointer">
        <i class="mdi mdi-transit-connection-variant me-1"></i>
        Connect Containers with Order
      </a>
      <a class="dropdown-item py-2"
         @click="showConnectOwnersModal(selected_rows)"
         style="cursor: pointer">
        <i class="mdi mdi-share-variant me-1"></i>
        Connect Containers with Customers
      </a>
    </div>
  </div>
</template>

<style scoped>

</style>