<script>
import axios from "axios";
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";

export default {
  name: "ConnectOwners",
  emits: ['onSuccess'],
  components: {
    Multiselect
  },
  data() {
    return {
      trains_options: [],
      terminal_options: [],
      showModal: false,
      containers_data: [],
      containers_plain_text: '',
      wagons_plain_text: '',
      arrival_dates_plain_text: '',
      dispatch_dates_plain_text: '',
      terminal: {id: '', name: ''},
      train: null,
      terminals: [],
      is_loading_containers_by_train: false,
      process_stage: 'input', // input, loading, success, error,
      error_data: {},

      has_dispatched_containers: false,

      passed_train_name_not_found: false,
      passed_train_name: '',
    }
  },
  methods: {
    async openModal(containers, train_name) {
      this.passed_train_name_not_found = false
      if (this.has_dispatched_containers) {
        this.resetModal()
      }
      // expects [{id: 1, name: 'container1'}, {id: 2, name: 'container2'}]
      // this.containers_data = containers_list
      // this.containers_plain_text = containers_list.map(container => container.name).join('\n')
      if (train_name) {
        await this.asyncFindTrainByName(train_name)
        if (this.trains_options.length === 0) {
          this.passed_train_name_not_found = true
          this.passed_train_name = train_name
          this.train = null
        } else {
          try {
            this.train = this.trains_options.find(train => train.label === train_name)
          } catch {
            this.train = null
            this.passed_train_name_not_found = true
            this.passed_train_name = train_name
          }
        }
      }
      this.showModal = true
    },
    closeModal() {
      this.showModal = false
    },

    checkDateFormat(lines) {
      const currentYear = new Date().getFullYear();
      return lines.filter(line => {
        if (!/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(line.trim())) {
          return true;
        }

        const [year, month, day] = line.split('-').map(Number);

        if (year > currentYear) {
          return true;
        }

        if (month < 1 || month > 12) {
          return true;
        }

        const daysInMonth = new Date(year, month, 0).getDate();
        return day < 1 || day > daysInMonth;
      });
    },
    async dispatchContainers() {
      this.process_stage = 'loading'
      try {
        await axios.post('/terminal/container_connect/connect_train/', {
          containers: this.containers_plain_text.split('\n').filter(container => container.trim()).map(container => container.trim()),
          wagons: this.wagons_plain_text.split('\n').filter(wagon => wagon.trim()).map(wagon => wagon.trim()),
          dispatch_dates: this.dispatch_dates_plain_text.split('\n').filter(date => date.trim()).map(date => date.trim()),
          arrival_dates: this.arrival_dates_plain_text.split('\n').filter(date => date.trim()).map(date => date.trim()),
          train_name: this.train.label,
          terminal_ids: this.terminals.map(terminal => terminal.value),
          terminal_names: this.terminals.map(terminal => terminal.label)
        })
        this.has_dispatched_containers = true
        this.process_stage = 'success'
        this.$emit('onSuccess')
      } catch (err) {
        try {
          this.error_data.message = err.response.data.message
          this.error_data.detail = err.response.data.errors.join('<br>')
        } catch {
          this.error_data.message = 'Something went wrong'
          this.error_data.detail = 'Please try again'
        }
        this.process_stage = 'error'
      }
    },
    async getContainersByTrain() {
      try {
        this.is_loading_containers_by_train = true
        let response = await axios.get(`/container_order/order_info_by_lot_number/${this.train.label}/`)
        this.containers_plain_text = response.data.map(container => container.container).join('\n')
        this.arrival_dates_plain_text = response.data.map(container => container.arrival_date).join('\n')
        this.is_loading_containers_by_train = false
      } catch {
        this.containers_plain_text = ''
        this.arrival_dates_plain_text = ''
        this.is_loading_containers_by_train = false
      }
    },
    async asyncFindTrainByName(query) {
      if (query.trim().length <= 0) return;
      let response = await axios.get(`/train/list/?name=${query.trim()}`);
      this.trains_options = response.data.results.map((train) => {
        return {
          value: train.id,
          label: train.name
        }
      });
    },
    async asyncFindTerminalsByName(query) {
      if (query.trim().length <= 0) return;
      let response = await axios.get("/terminal/", {
        params: {
          name: query.trim(),
        },
      });
      this.terminal_options = response.data.results.map((train) => {
        return {
          value: train.id,
          label: train.name
        }
      });
    },
    resetModal() {
      this.showModal = false
      this.process_stage = 'input'
      this.containers_data = []
      this.containers_plain_text = ''
      this.wagons_plain_text = ''
      this.dispatch_dates_plain_text = ''
      this.error_data = {}
      this.terminal = {id: '', name: ''}
      this.has_dispatched_containers = false

      this.$refs.selectTerminal.clear()

    }
  },
  computed: {
    incorrect_containers() {
      return this.containers_plain_text.split('\n')
          .map(container => container.trim())
          .filter(container => container.length !== 11 || !/^[A-Z]{3}U[0-9]{7}$/.test(container))
    },
    incorrect_wagons() {
      const lines = this.wagons_plain_text.split('\n');

      return lines.filter(line => {
        return line.trim() && (/[^0-9]/.test(line) || /^0+$/.test(line));
      });
    },
    incorrect_arrival_dates() {
      const lines = this.arrival_dates_plain_text.split('\n');
      return this.arrival_dates_plain_text.trim().length > 0 ? this.checkDateFormat(lines) : []
    },
    incorrect_dispatch_dates() {
      const lines = this.dispatch_dates_plain_text.split('\n');
      return this.dispatch_dates_plain_text.trim().length > 0 ? this.checkDateFormat(lines) : []
    },

    containers_count() {
      return this.containers_plain_text.trim().length ? this.containers_plain_text.split('\n').length : 0
    },
    wagons_count() {
      return this.wagons_plain_text.trim().length ? this.wagons_plain_text.split('\n').length : 0
    },
    arrival_dates_count() {
      return this.arrival_dates_plain_text.trim().length ? this.arrival_dates_plain_text.split('\n').length : 0
    },
    dispatch_dates_count() {
      return this.dispatch_dates_plain_text.trim().length ? this.dispatch_dates_plain_text.split('\n').length : 0
    },

    canSubmitForm() {
      let containers_length = this.containers_plain_text.split('\n').length
      let wagons_length = this.wagons_plain_text.split('\n').length
      let arrival_dates_length = this.arrival_dates_plain_text.split('\n').length
      let dispatch_dates_length = this.dispatch_dates_plain_text.split('\n').length

      let wagons_status = true
      let arrival_dates_status = true

      if (arrival_dates_length && this.arrival_dates_plain_text.trim().length) {
        arrival_dates_status = containers_length === arrival_dates_length
      }

      if (wagons_length && this.wagons_plain_text.trim().length) {
        wagons_status = containers_length === wagons_length
      }

      return this.terminals.length > 0
          && this.incorrect_containers.length === 0
          && this.incorrect_wagons.length === 0
          && this.containers_plain_text.length >= 11
          && this.dispatch_dates_plain_text.length >= 9
          && this.incorrect_dispatch_dates.length === 0
          && containers_length === dispatch_dates_length
          && wagons_status && arrival_dates_status
    },

    has_different_terminal_names() {
      return !this.containers_data.map(item => item.terminal).every(terminal => terminal.name === this.containers_data[0].terminal.name)
    },
    terminal_name() {
      return this.containers_data.length === 0 || this.has_different_terminal_names ? '--' : this.containers_data[0].terminal.name
    }
  },
  watch: {
    containers_data: {
      handler() {
        this.terminal = {id: '', name: ''}
      },
      immediate: true,
    },
    train: {
      handler(value) {
        if (value) {
          this.getContainersByTrain()
        } else {
          this.containers_data = []
        }
      },
      immediate: true
    }
  }
}
</script>

<template>
  <b-modal
      v-model="showModal" id="dispatchContainersModal" modal-class="zoomIn" hide-footer
      header-class="p-3 bg-soft-success" class="v-modal-custom" centered size="xl"
      title="Dispatch Containers"
  >

    <form @submit.prevent="dispatchContainers()" :class="{'d-none' : process_stage !=='input'}">

      <b-row class="mb-4">
        <b-col sm="12" lg="6">
          <label for="selectTerminal" class="form-label">
            Select Terminal
            <span class="text-danger">*</span>
          </label>
          <Multiselect v-model="terminals" :options="terminal_options" searchable :object="true" mode="tags"
                       @search-change="asyncFindTerminalsByName($event)"></Multiselect>
        </b-col>
        <b-col sm="12" lg="6">
          <label for="selectTerminal" class="form-label">
            Train Name
            <span class="text-danger">*</span>
          </label>
          <Multiselect v-model="train" :options="trains_options" searchable :object="true"
                       @search-change="asyncFindTrainByName($event)"
          />
        </b-col>
      </b-row>

      <!-- Danger Alert -->
      <b-alert v-if="passed_train_name_not_found" show dismissible variant="danger" class="fade show" role="alert">
        <strong> {{ passed_train_name }} </strong> - Train not found in the system
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </b-alert>


      <b-row gutter-y="4" v-if="train">
        <b-col sm="12" lg="3">
          <div class="d-flex justify-content-between align-items-center">
            <label class="form-label" for="dispatchContainersNames">
              Container Names
              <span class="text-danger">*</span>
            </label>
            <i class="ri-question-line align-middle"></i>
          </div>
          <textarea
              class="form-control mb-2"
              id="dispatchContainersNames" :rows="containers_plain_text.split('\n').length || 20"
              :value="containers_plain_text" disabled
              placeholder="Paste containers names here"
          >
          </textarea>
          <VTooltip>
            <span id="dispatchContainersNamesHelp" class="form-text">
              <i class="ri-information-line align-middle"></i>
              Must be separated by line
              <span class="fw-bolder">( {{ containers_count }} )</span>
            </span>
            <template v-slot:popper>
              Each container name must be 11 characters long <br>
              Each line represents a single container name
            </template>
          </VTooltip>
        </b-col>
        <b-col sm="12" lg="3">
          <label class="form-label" for="dispatchWagonsNames">
            Wagon Names
            <span class="text-danger">*</span>
          </label>
          <textarea
              class="form-control mb-2"
              id="dispatchWagonsNames" :rows="containers_plain_text.split('\n').length || 20"
              v-model="wagons_plain_text"
          >
          </textarea>
          <VTooltip>
            <span id="dispatchWagonsNamesHelp" class="form-text">
              <i class="ri-information-line align-middle"></i>
              Must be separated by line
               <span class="fw-bolder">( {{ wagons_count }} )</span>
            </span>
            <template v-slot:popper>
              Each wagon name must contain only valid numbers <br>
              Each line represents a single wagon name
            </template>
          </VTooltip>
        </b-col>
        <b-col sm="12" lg="3">
          <label class="form-label" for="arrivalDates">
            Arrival Dates
            <span class="text-danger">*</span>
          </label>
          <textarea
              class="form-control mb-2"
              id="arrivalDates" :rows="containers_plain_text.split('\n').length || 20"
              :value="arrival_dates_plain_text" disabled
              placeholder="Paste arrival dates here"
          >
          </textarea>
          <VTooltip>
            <span id="arrivalDatesHelp" class="form-text">
              <i class="ri-information-line align-middle"></i>
              Must be in YYYY-MM-DD format
              <span class="fw-bolder">( {{ arrival_dates_count }} )</span>
            </span>
            <template v-slot:popper>
              Each date must be in YYYY-MM-DD format <br>
            </template>
          </VTooltip>
        </b-col>
        <b-col sm="12" lg="3">
          <label class="form-label" for="dispatchDates">
            Dispatched Dates
            <span class="text-danger">*</span>
          </label>
          <textarea
              class="form-control mb-2"
              id="dispatchDates" :rows="containers_plain_text.split('\n').length || 20"
              v-model="dispatch_dates_plain_text"
              placeholder="Paste dispatched dates here"
          >
          </textarea>
          <VTooltip>
            <span id="dispatchDatesHelp" class="form-text">
              <i class="ri-information-line align-middle"></i>
              Must be in YYYY-MM-DD format
              <span class="fw-bolder">( {{ dispatch_dates_count }} )</span>
            </span>
            <template v-slot:popper>
              Each date must be in YYYY-MM-DD format <br>
            </template>
          </VTooltip>
        </b-col>

        <b-col sm="12" class="text-center mt-4">
          <div class="text-start mb-3" v-if="incorrect_containers.filter(a => a).length >0">
            <b-alert show variant="danger" class="d-flex gap-3 flex-wrap text-danger border-0 "
                     role="alert">
              <div class="d-flex flex-column">
                <h6 class="text-danger d-block">Incorrect Containers</h6>
                <span v-for="ctr in incorrect_containers"
                      :key="`incorrect_container_${ctr}`"
                >
                  <span v-if="ctr">{{ ctr || '--' }}</span>
                  <span v-else>-</span>
                </span>
              </div>
            </b-alert>
          </div>
          <div class="text-start mb-3" v-if="incorrect_wagons.length > 0">
            <b-alert show variant="danger" class="d-flex gap-3 flex-wrap text-danger border-0 "
                     role="alert">
              <div class="d-flex flex-column">
                <h6 class="text-danger d-block">Incorrect Wagons</h6>
                <span v-for="wagon in incorrect_wagons"
                      :key="`incorrect_wagon_${wagon}`"
                >
                  <span v-if="wagon">{{ wagon || '--' }}</span>
                  <span v-else>-</span>
                </span>
              </div>
            </b-alert>
          </div>
          <div class="text-start" v-if="incorrect_arrival_dates.length > 0">
            <b-alert show variant="danger" class="d-flex gap-3 flex-wrap text-danger border-0 "
                     role="alert"
            >
              <div class="d-flex flex-column">
                <h6 class="text-danger d-block">Incorrect Dates</h6>
                <span v-for="date in incorrect_arrival_dates"
                      :key="`incorrect_date_${date}`"
                >
              <span v-if="date">{{ date || '--' }}</span>
              <span v-else>-</span>
              </span>
              </div>
            </b-alert>
          </div>
          <div class="text-start" v-if="incorrect_dispatch_dates.length > 0">
            <b-alert show variant="danger" class="d-flex gap-3 flex-wrap text-danger border-0 "
                     role="alert"
            >
              <div class="d-flex flex-column">
                <h6 class="text-danger d-block">Incorrect Dates</h6>
                <span v-for="date in incorrect_dispatch_dates"
                      :key="`incorrect_date_${date}`"
                >
              <span v-if="date">{{ date || '--' }}</span>
              <span v-else>-</span>
              </span>
              </div>
            </b-alert>
          </div>

          <b-button type="submit" variant="success"
                    :disabled="!canSubmitForm"
          >
            Confirm & Dispatch
          </b-button>
        </b-col>
      </b-row>
    </form>

    <div v-if="process_stage === 'loading'">
      <div class="text-center py-5">
        <div class="spinner-border text-success mb-4" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <h3>Dispatching containers...</h3>
      </div>
    </div>
    <div v-else-if="process_stage ==='success'">
      <div class="text-center py-5">
        <i class="mdi mdi-check-circle-outline text-success mb-4" style="font-size: 5rem"></i>
        <h3 class="mb-4">Successfully dispatched containers</h3>
        <b-button @click="closeModal()" variant="success">OK</b-button>
      </div>
    </div>
    <div v-else-if="process_stage ==='error'">
      <div class="text-center">
        <i class="mdi mdi-close-circle-outline text-danger" style="font-size: 5rem"></i>
        <h2 class="my-3 mb-3">
          {{ error_data.message || 'Something went wrong' }}
        </h2>
        <p class="fs-4 mb-4" v-html="error_data.detail || 'Please try again'"/>
        <b-button size="lg" variant="success" @click="process_stage = 'input'">Try Again</b-button>
      </div>
    </div>
  </b-modal>

</template>

<style scoped>

</style>