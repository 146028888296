<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "message",
    props: {
        message: {
            type: String,
            required: true
        },
        file: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            message_font_size: 14,
            is_downloading_file: false
        }
    },
    methods: {
        formatMessage(message) {
            try {
                return message.trim().replace(/(?:\r\n|\r|\n)/g, '<br>')
            } catch {
                return ''
            }
        },
        messageHasFile() {
            return this.file !== null
        },
        downloadFile() {
            let url = process.env.VUE_APP_ORDER_URL + this.file;
            this.is_downloading_file = true
            fetch(url)
                .then(resp => resp.blob())
                .then(blobobject => {
                    const blob = window.URL.createObjectURL(blobobject);
                    const anchor = document.createElement('a');
                    anchor.style.display = 'none';
                    anchor.href = blob;
                    anchor.download = url.toString().split('/')[url.toString().split('/').length - 1];
                    document.body.appendChild(anchor);
                    anchor.click();
                    window.URL.revokeObjectURL(blob);
                    this.is_downloading_file = false
                })
                .catch(() => alert('An error in downloading the file sorry'));
        }
    }
})
</script>

<template>
    <div class="acitivity-item d-flex align-items-end my-2 mb-3">
        <div class="flex-shrink-0">
            <img src="https://cdn2.iconfinder.com/data/icons/telegram/154/telegram-ui-avatar-man-512.png"
                 alt=""
                 class="avatar-sm rounded-circle acitivity-avatar">
        </div>
        <div class="flex-grow-1 ms-3">
            <b-card no-body class="telegram-message mb-0 py-1 rounded-3 shadow-none">
                <b-card-body class="rounded-3 py-2 fs-5">
                    <p class="my-0" v-html="formatMessage(message)"/>
                    <div class="message-time-received">
                        <span class="text-muted">13:15</span>
                    </div>
                </b-card-body>
            </b-card>
            <div v-if="messageHasFile" @click="downloadFile()"
                 class="telegram-message-footer text-center mt-2 cursor-pointer">
                <button class="telegram-btn btn text-light fs-5">
                    <span v-if="is_downloading_file">
                        <!-- Border spinner -->
                        <b-spinner variant="primary" class="align-middle me-2"></b-spinner>
                        Downloading...
                    </span>
                    <span v-else>Download File</span>
                </button>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>