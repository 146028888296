<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "Footer"
})
</script>

<template>
    <div class="offcanvas-foorter text-center">
        <b-card no-body class="mb-0 rounded-0">
            <b-card-body>
                <h5 class="link-primary my-0">
                    MUTE
                </h5>
            </b-card-body>
        </b-card>
    </div>
</template>

<style scoped>

</style>