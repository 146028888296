<script>
import SelectCompany from "@/components/custom/SelectCompany.vue";
import axios from "axios";

export default {
  name: "ConnectCustomers",
  emits: ['onSuccess'],
  data() {
    return {
      showModal: false,
      containers_data: [],
      containers_plain_text: '',
      companies: [],
      process_stage: 'input', // input, loading, success, error,
      error_data: {},
    }
  },
  components: {
    SelectCompany
  },
  methods: {
    openModal(containers_list) {
      if (containers_list) {
        try {
          this.containers_data = containers_list
          this.containers_plain_text = containers_list.map(container => container.name).join('\n')

          if (containers_list.length === 1) {
            this.companies = (containers_list[0].customers || []).map(customer => {
              return {
                value: customer.id,
                label: customer.name
              }
            })
          }

          this.showModal = true
        } catch {
          this.showModal = true
        }
      } else {
        this.showModal = true
      }
    },
    closeModal() {
      this.showModal = false
    },
    onCompanySelect(event, index) {
      if (event) {
        this.companies.push(event)
      } else {
        this.companies.splice(index, 1)
      }
    },
    addCompany(event) {
      if (event) {
        this.companies.push(event)
        setTimeout(() => {
          this.$refs.addCompany.unselectCompany()
        }, 5)
      }
    },
    async connectToOwners() {
      this.process_stage = 'loading'
      try {
        await axios.post('/terminal/container/connect_customer/', {
          customer_ids: this.companies.map(company => company.value),
          containers: this.containers_plain_text.split('\n').map(container => container.trim())
        })
        this.process_stage = 'success'
        this.$emit('onSuccess')
        setTimeout(() => {
          this.resetModal()
        }, 2000)
      } catch (err) {
        try {
          this.error_data.message = err.response.data.message
          this.error_data.detail = err.response.data.extra.fields.containers[0]
        } catch {
          this.error_data.message = 'Something went wrong'
          this.error_data.detail = 'Please try again'
        }
        this.process_stage = 'error'
      }
    },
    resetModal() {
      this.showModal = false
      setTimeout(() => {
        this.process_stage = 'input'
        this.companies = []
        this.containers_data = []
        this.containers_plain_text = ''
        this.error_data = {}
      }, 500)
    }
  },
  computed: {
    incorrect_containers() {
      return this.containers_plain_text.split('\n')
          .map(container => container.trim())
          .filter(container => container.length !== 11 || !/^[A-Z]{3}U[0-9]{7}$/.test(container))
    }
  }
}
</script>

<template>
  <b-modal
      v-model="showModal" id="connectOwnersModal" modal-class="zoomIn" hide-footer
      header-class="p-3 bg-soft-success" class="v-modal-custom" centered size="lg"
      title="Connect to Customers"
  >

    <form @submit.prevent="connectToOwners()" :class="{'d-none' : process_stage !=='input'}">
      <b-row gutter-y="4">
        <b-col sm="12" lg="6">
          <label class="form-label" for="selectedContainers">Container Names</label>
          <textarea
              class="form-control"
              id="selectedContainers" :rows="containers_data.length || 20"
              v-model="containers_plain_text"
              placeholder="Paste containers names here"
          >
        </textarea>
        </b-col>
        <b-col sm="12" lg="6">
          <label class="form-label">Companies</label>
          <b-row class="gy-2">
            <b-col sm="12" v-for="(company, index) in companies" :key="`company_${company.value}`">
              <SelectCompany :current_company="{
              id: company.value,
              name: company.label
            }" @onSelect="onCompanySelect($event, index)"/>
            </b-col>
            <b-col sm="12">
              <SelectCompany ref="addCompany" @onSelect="addCompany($event)"/>
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="12" class="text-center mt-4">
          <div class="text-start" v-if="incorrect_containers.filter(a => a).length >0">
            <b-alert show variant="danger" class="d-flex gap-3 flex-wrap text-danger fw-semibold border-0 "
                     role="alert">
            <span v-for="ctr in incorrect_containers"
                  :key="`incorrect_container_${ctr}`"
            >
              <span v-if="ctr">{{ ctr || '--' }}</span>
              <i class="mdi mdi-keyboard-space" v-else></i>
            </span>
            </b-alert>
          </div>
          <b-button type="submit" variant="success" :disabled="incorrect_containers.length >0">
            Confirm & Connect
          </b-button>
        </b-col>
      </b-row>
    </form>
    <div v-if="process_stage === 'loading'">
      <div class="text-center py-5">
        <div class="spinner-border text-success mb-4" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <h3>Connecting to customers...</h3>
      </div>
    </div>
    <div v-else-if="process_stage ==='success'">
      <div class="text-center py-5">
        <i class="mdi mdi-check-circle-outline text-success mb-4" style="font-size: 5rem"></i>
        <h3>Successfully connected to customers</h3>
      </div>
    </div>
    <div v-else-if="process_stage ==='error'">
      <div class="text-center">
        <i class="mdi mdi-close-circle-outline text-danger" style="font-size: 5rem"></i>
        <h2 class="my-3 mb-3">
          {{ error_data.message || 'Something went wrong' }}
        </h2>
        <p class="fs-4 mb-4">
          {{ error_data.detail || 'Please try again' }}
        </p>
        <b-button size="lg" variant="success" @click="process_stage = 'input'">Try Again</b-button>
      </div>
    </div>
  </b-modal>

</template>

<style scoped>

</style>