<template>

  <b-modal v-model="showModal" hide-footer
           title="Add Container Seal Image"
           class="v-modal-custom" size="lg">
    <form class="form">
      <div v-if="images.length === 0">
        <label class="form-label">Container Seal Image</label>
        <input type="file" id="container_seal_img" class="form-control" placeholder="Seal Image"
               @change="handleFileUpload" multiple>
      </div>
      <div v-else>
        <div v-for="(img, index) in images" :key="img" class="border rounded-2 px-3 pt-3 mb-3">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <figure class="figure">
                <b-img :src="img.src" class="img-fluid w-100" alt="Responsive image"></b-img>
                <figcaption class="figure-caption d-flex justify-content-between align-items-center pt-4">
                  <span>{{ img.name }}</span>
                  <b-button variant="danger" class="btn-sm" @click="deleteImg(index)">
                    <i class="bx bx-trash me-1"></i>
                    Delete
                  </b-button>
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
        <div class="text-end">
          <b-button @click="uploadImage" variant="success">Add Seal Image</b-button>
        </div>
      </div>
    </form>
  </b-modal>

</template>

<script>
import axios from "axios";

export default {
  emits: ['update'],
  name: "container_img_create",
  data() {
    return {
      img: null,
      images: [],
      showModal: false,
      img_id: null
    }
  },
  components: {
    // lottie: Lottie,
  },
  methods: {
    openModal(img_id) {
      this.img_id = img_id
      this.showModal = true
    },
    async handleFileUpload(event) {
      const files = Array.from(event.target.files);
      this.images = await Promise.all(files.map(async (file) => {
        try {
          const src = await this.readFile(file);
          return {name: file.name, file: file, src: src};
        } catch (error) {
          console.error('Error reading file:', error);
          return null;
        }
      }));
      this.images = this.images.filter(img => img !== null);
    },
    readFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    deleteImg(index) {
      this.images.splice(index, 1);
    },
    async uploadImage() {
      let data = new FormData();
      this.images.forEach((img) => {
        data.append('images', img.file); // Use 'images' as the key for all files
      });

      let config = {
        method: 'post',
        url: `/terminal/containers/${this.img_id}/image/create/`,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: data
      };

      axios(config)
          .then((response) => {
            if (response.status === 201) {
              this.showModal = false;
              this.img_id = null
              this.images = []
              this.$emit('update');
            }
          })
          .catch((error) => {
            alert("Something went wrong");
            console.log(error);
          });
    }
  },
}
</script>

<style scoped>

</style>