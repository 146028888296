<script>
import PageHeader from "@/components/page-header";
import TerminalWidgets from "./TerminalWidgets.vue";
import TerminalContainersTable from "./TerminalContainers.vue";
import TelegramHistory from "@/views/pages/terminal/models/TelegramHistory.vue";
import ExpectedTrainsStatistics from "@/views/pages/terminal/components/ExpectedTrainsStatistics.vue";

export default {
  page: {
    title: "Job Dashboard",
    meta: [{name: "description", content: 'cc'}],
  },
  data() {
    return {
      title: "Terminal",
      items: [
        {
          text: "Dashboards",
          href: "/",
        },
        {
          text: "Terminal",
          active: true,
        },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.state.user
    },
  },
  components: {
    PageHeader,
    TerminalWidgets,
    TerminalContainersTable,
    TelegramHistory,
    ExpectedTrainsStatistics
  },
};
</script>

<template>
  <PageHeader :title="title" :items="items"/>

  <div class="row">
    <TerminalWidgets @containersSyncedWithExpenses="this.$refs.terminalContainersTable.$refs.terminalTable.getData()"/>
    <div class="col-12">
      <ExpectedTrainsStatistics/>
    </div>
  </div>

  <TerminalContainersTable ref="terminalContainersTable"/>

  <TelegramHistory/>

</template>
