<script>
import AddExpense from "@/views/pages/terminal/components/AddExpense.vue";
import SeeExpenses from "@/views/pages/terminal/components/SeeExpenses.vue";

export default {
  name: "TerminalSettings",
  components: {
    AddExpense,
    SeeExpenses
  },
  data() {
    return {
      show_terminal_settings_modal: false,
      current_terminal: {},

      current_action: '',
      action: {
        add_expense: 'add_expense',
        see_expenses: 'see_expenses'
      },
    }
  },
  methods: {
    showModal(terminal, action) {
      this.current_action = action;
      this.current_terminal = terminal;
      this.show_terminal_settings_modal = true;
    },
    addExpense(terminal) {
      this.showModal(terminal, this.action.add_expense);
    },
    async getExpenses(terminal) {
      this.showModal(terminal, this.action.see_expenses);
    }
  },
}
</script>

<template>
  <b-modal v-model="show_terminal_settings_modal" hide-footer
           title="Terminal Settings"
           dialog-class="modal-fullscreen-sm-down"
           class="v-modal-custom" centered
           :size="current_action === action.add_expense ? 'sm' : 'xl'"
  >

    <template v-if="current_action === action.add_expense">
      <AddExpense :terminal="current_terminal" @view_expenses="current_action = action.see_expenses"/>
    </template>
    <template v-if="current_action === action.see_expenses">
      <SeeExpenses :terminal="current_terminal" @add_expense="addExpense" />
    </template>
  </b-modal>
</template>

<style scoped>

</style>