<script>
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment/moment";

export default {
  name: "ExpenseUpdateModal",
  emits: ['expenseAmountUpdated', 'expenseAmountDeleted'],
  props: {
    amount: {
      type: Object,
      default: () => {
      },
    },
    expense: {
      type: Object,
      default: () => {
      },
    }
  },
  data() {
    return {
      showModal: false,
      deletion_confirmed: false
    }
  },
  computed: {
    is_duplicate() {
      if (!this.expense || !this.expense.amounts) return false;
      return this.expense.amounts.filter((e) => {
        return (e.container_type === this.amount.container_type && parseFloat(e.amount || 0) === parseFloat(this.amount.amount || 0) && e.validation_date === this.amount.validation_date)
            || (e.container_type === this.amount.container_type && e.validation_date === this.amount.validation_date)
      }).length > 1
    },
    amount_computed() {
      return this.amount || {
        container_type: null,
        amount: 0,
        validation_date: new Date().toISOString().split('T')[0]
      }
    },
    expense_computed() {
      return this.expense || {
        expense_type: null,
        amounts: []
      }
    }
  },
  components: {
    Multiselect
  },
  methods: {
    async updateExpenseAmount() {
      if (this.is_duplicate) return;
      let Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
      })
      if (!this.amount_computed.id || !this.expense_computed.id) {
        await Toast.fire({
          icon: "error",
          title: "Expense not found",
          text: "Please, inform developers about this issue",
        })
        return;
      }
      try {
        await axios.put(`/terminal/terminal_expanse_amount/${this.amount_computed.id}/update/`, {
          id: this.amount_computed.id,
          terminal_expanse_id: this.expense_computed.id,
          container_type: this.amount_computed.container_type,
          amount: this.amount_computed.amount,
          validation_date: this.amount_computed.validation_date,
          is_laden: this.amount_computed.is_laden
        })
        this.$emit('expenseAmountUpdated');
        this.showModal = false;
        await Toast.fire({
          icon: "success",
          title: "Expense updated",
        })
      } catch (e) {

        try {
          let error_title = e.response.data.message || "Error updating expense";
          let error_text = Object.entries(e.response.data.extra.fields).map(([key, value]) => {
            return `<b>${key.split('_')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')}:</b> ${value.join(', ')} <br>`
          }).join('\n') || "Please, inform developers about this issue";
          await Toast.fire({
            icon: "error",
            title: error_title,
            html: error_text,
          })
        } catch {
          await Toast.fire({
            icon: "error",
            title: "Error updating expense",
            text: "Please, inform developers about this issue",
          })
        }

      }
    },
    async deleteExpenseAmount() {
      let Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
      })
      if (!this.amount_computed.id || !this.expense_computed.id) {
        await Toast.fire({
          icon: "error",
          title: "Expense not found",
          text: "Please, inform developers about this issue",
        })
        return;
      }
      try {
        await axios.delete(`/terminal/terminal_expanse_amount/${this.amount_computed.id}/delete/`)
        this.$emit('expenseAmountDeleted');
        await Toast.fire({
          icon: "success",
          title: "Expense deleted",
        })
      } catch {
        await Toast.fire({
          icon: "error",
          title: "Error deleting expense",
          text: "Please, inform developers about this issue",
        })
      }
    },
    async deleteConfirmation() {
      this.deletion_confirmed = true
    },
    formatDate(date) {
      return moment(date).format('ll')
    }
  },
}
</script>

<template>
  <form @submit.prevent="updateExpenseAmount()" v-if="amount">
    <b-card-body class="mb-0">
      <p class="text-muted">
        {{ amount_computed.is_laden ? 'Laden' : 'Empty' }} containers with type {{ amount_computed.container_type }} are
        charged at
        {{
          parseFloat(amount_computed.amount || 0).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
        }} {{ expense_computed.expense_type === 'daily' ? 'per day' : 'one time' }} until
        {{ formatDate(amount_computed.validation_date) }}
      </p>
      <div class="d-flex flex-column gap-3">
        <!-- Secondary Alert -->
        <b-alert v-if="is_duplicate" show variant="danger" class="border-0 mb-2" role="alert">
          <strong> Hey - </strong> This expense already exists
        </b-alert>

        <div>
          <label class="form-label">Container type</label>
          <Multiselect v-model="amount_computed.container_type" :options="['20', '20HC', '40', '40HC', '45']"/>
        </div>
        <div>
          <label class="form-label">
            Price <small class="text-muted">(USD)</small>
          </label>
          <input class="form-control" placeholder="Enter price" type="number" step="any"
                 v-model="amount_computed.amount">
        </div>
        <div>
          <label class="form-label">
            Valid until
          </label>
          <input class="form-control" type="date" v-model="amount_computed.validation_date">
        </div>
        <div>
          <div class="form-check form-check-outline form-check-success">
            <input v-model="amount_computed.is_laden" class="form-check-input" type="checkbox"
                   :id="`amount${amount_computed.id}IsLaden`">
            <label class="form-check-label" :for="`amount${amount_computed.id}IsLaden`">
              Is Laden
            </label>
          </div>
        </div>
      </div>
    </b-card-body>
    <b-card-footer v-if="deletion_confirmed" class="hstack gap-2">
      <b-button @click="deleteExpenseAmount()" type="button" variant="danger" size="sm" class="w-100">
        <i class="bx bxs-trash-alt align-middle me-1"></i> Yes, Delete
      </b-button>
      <b-button @click="deletion_confirmed = false" variant="light" size="sm" class="w-100" type="button">
        <i class="mdi mdi-close fw-bold align-bottom me-1"></i> No, Keep it
      </b-button>
    </b-card-footer>
    <b-card-footer v-else class="hstack gap-2">
      <b-button type="submit" variant="success" size="sm" class="w-100">
        <i class="mdi mdi-check-bold align-bottom me-1"></i> Save
      </b-button>
      <b-button @click="deleteConfirmation()" variant="danger" size="sm" class="w-100" type="button">
        <i class="mdi mdi-close fw-bold align-bottom me-1"></i> Delete
      </b-button>
    </b-card-footer>
  </form>
</template>

<style scoped>

</style>